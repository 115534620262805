import React, { useCallback, useEffect, useState } from "react";
import { API, APIURLS } from "../../../../Services";
import { userProps } from "../../../../Interfaces";
import { AppNoData } from "../../../Atoms/AppNoData";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import { RiDeleteBinLine, RiEditBoxLine, RiMoreLine } from "react-icons/ri";

import { BsClockHistory, BsToggleOff, BsToggleOn } from "react-icons/bs";
import { Link } from "react-router-dom";
import moment from "moment";
import toast from "react-hot-toast";
import { useAuthContext } from "../../../../ContextProviders/AuthProvider";
export const AppUserListComponent: React.FC = () => {
  const [users, setusers] = useState<userProps[]>([]);
  const {accessToken} = useAuthContext()
  const syscUsers = useCallback(async () => {
    try {
      const response = await API.get(`${APIURLS.users.all}`,{headers:{'Authorization':`Bearer ${accessToken}`}});
      const userReponse: userProps[] = response.data;
      setusers(userReponse);
    } catch (error) {
      console.log(`API error ${error}`);
    }
  }, [accessToken]);

  useEffect(() => {
    syscUsers();
  }, [syscUsers]);

  const onDeleteHandler =async(id:number)=>{
    console.log(id)
    try {
      const {data} = await API.delete(`${APIURLS.users.delete}/${id}`,{headers:{'Authorization':`Bearer ${accessToken}`}});
      if(data){
        toast.success(`User deleted`,{icon:<RiDeleteBinLine className='w-auto h-6' />});
        await syscUsers()
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <React.Fragment>
      {users && users.length > 0 ? (
        <React.Fragment>
          <div className="w-full px-2 py-2 flex flex-col space-y-2">
            <h4 className="font-bold text-xl text-slate-950">
              {users.length} Users
            </h4>
            <div className="w-full  block h-screen ">
              <ReactDataGrid
                idProperty="id"
                
                showEmptyRows={false}
                columns={[
                  
                  {
                    name: "first_name",
                    header: "First Name",
                  },
                  {
                    name: "last_name",
                    header: "Last Name",
                  },
                  {
                    name: "email",
                    header: "Email",
                  },
                  {
                    name: "phone",
                    header: "Phone",
                  },
                  {
                    name: "role",
                    header: "Role",
                  },
                  {
                    name: "updated_at",
                    header: <BsClockHistory className="w-auto h-6" />,
                    minWidth: 200,
                    render: ({ value }) => (
                      <React.Fragment>
                        <div className="w-full flex flex-col space-y-0 justify-center items-center content-center">
                          <span className="text-xs font-normal">
                            {moment(new Date(value)).format("YYYY-MM-DD")}(
                            {moment(new Date(value)).fromNow()})
                          </span>
                        </div>
                      </React.Fragment>
                    ),
                    headerAlign: "center",
                  },

                  {
                    name: "is_active",
                    header: "Published?",
                    headerAlign: "center",
                    render: ({ value }) => (
                      <React.Fragment>
                        <div className="w-full flex flex-row justify-center items-center content-center">
                          {Boolean(value) ? (
                            <BsToggleOn className="text-green-600 w-auto h-6 text-center" />
                          ) : (
                            <BsToggleOff className="text-red-600 w-auto h-6 text-center" />
                          )}
                        </div>
                      </React.Fragment>
                    ),
                  },
                  {
                    name: "id",
                    header: <RiMoreLine />,
                    minWidth: 200,
                    render: ({ value }) => (
                      <div className="w-full flex flex-row justify-center items-center content-center space-x-4">
                        <Link to={`/dashboard/user_update/${value}`}>
                          <RiEditBoxLine className="w-auto h-6 text-yellow-600" />
                        </Link>
                        <button onClick={()=>onDeleteHandler(value)} type="button" className="px-2 py-2">
                          <RiDeleteBinLine className="w-auto h-6 text-primary" />
                        </button>
                      </div>
                    ),
                  },
                ]}
                dataSource={users}
                className="w-full overflow-y-auto h-5/6"
                
                pagination={true}
                filterable
              />
            </div>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <AppNoData message="No users in database." />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

import React, { useState } from "react";

interface dashbaordContextType {
  isAside: boolean;
  setIsAside: React.Dispatch<React.SetStateAction<boolean>>;
}

const DashboardContext = React.createContext<dashbaordContextType | undefined>(
  undefined
);

export const useDashboardContext = (): dashbaordContextType => {
  const context = React.useContext(DashboardContext);
  if (!context) {
    throw new Error(
      `useDashboardContext must be within a useDashboardContextProvider`
    );
  } else {
    return context;
  }
};
interface props {
  children: React.ReactNode;
}
export const AppDashboardContextProvider: React.FC<props> = ({ children }) => {
  const [isAside, setisAside] = useState(false);
  return (
    <React.Fragment>
      <DashboardContext.Provider
        value={{ isAside: isAside, setIsAside: setisAside }}
      >
        {children}
      </DashboardContext.Provider>
    </React.Fragment>
  );
};

import React from "react";
import { AppUserEditFormComponent } from "../../../Components/Organisms/Dashbaord/User/AppUserEditForm";

export const AppUserUpdateScreen: React.FC = () => {
  return (
    <React.Fragment>
      <AppUserEditFormComponent />
    </React.Fragment>
  );
};

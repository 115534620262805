import React from "react";
import { Controller, useForm } from "react-hook-form";
import { API, APIURLS } from "../../../../Services";
import toast from "react-hot-toast";
import { RiAddCircleLine } from "react-icons/ri";
import { useAuthContext } from "../../../../ContextProviders/AuthProvider";

export const AppShippingCreateFormComponent: React.FC = () => {
  const {
    control,
    handleSubmit,

   
  } = useForm({
    mode: "onChange",

    defaultValues: {
      name: "",
      code: "",
      calc_shipping_cost: 0,
      calc_shipping_extra: 0,
      city:""
    },
  });
  const {accessToken} = useAuthContext()
  const onSubmit = (data: any) => {
    if (data) {
      API.post(`${APIURLS.shipping.create}`, {
        name: data.name,
        code: data.code,
        calc_shipping_cost: Number(data.calc_shipping_cost),
        calc_shipping_extra: Number(data.calc_shipping_extra),
      },{headers:{'Authorization':`Bearer ${accessToken}`}})
        .then((res) => {
          if (res.data) {
            toast.success("New shipping created.", {
              className: "text-sm font-normal",
              icon: <RiAddCircleLine className="w-auto h-6" />,
            });
          }
        })
        .catch((err: any) => {
          toast.success(`${JSON.stringify(err.message)}`, {
            className: "text-sm font-normal",
            icon: <RiAddCircleLine className="w-auto h-6" />,
          });
        })
        .finally(() => {
          console.log(`Shipping create API done.`);
        });
    }
  };
  return (
    <React.Fragment>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full flex flex-col  max-w-screen-md space-y-2"
      >
        <div className="flex flex-col space-y-2 ">
          <label className="text-sm font-normal" htmlFor="last_name">
            Name
          </label>
          <Controller
            name="name"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Please Don't Leave It Blank.",
              },
            }}
            render={({
              field: { onBlur, onChange, value, ref },
              formState: { errors },
            }) => (
              <React.Fragment>
                <input
                  type="text"
                  ref={ref}
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  onBlur={onBlur}
                  className="w-full bg-white  form-input border-white "
                />
                {errors && errors.name && errors.name.message ? (
                  <React.Fragment>
                    <span className="text-xs text-primary font-light">
                      {errors.name.message}
                    </span>
                  </React.Fragment>
                ) : null}
              </React.Fragment>
            )}
          />
        </div>
        <div className="flex flex-col space-y-2 ">
          <label className="text-sm font-normal" htmlFor="last_name">
            City
          </label>
          <Controller
            name='city'
            control={control}
            rules={{
              required: {
                value: true,
                message: "Please Don't Leave It Blank.",
              },
            }}
            render={({
              field: { onBlur, onChange, value, ref },
              formState: { errors },
            }) => (
              <React.Fragment>
                <input
                  type="text"
                  ref={ref}
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  onBlur={onBlur}
                  className="w-full bg-white  form-input border-white "
                />
                {errors && errors.city && errors.city.message ? (
                  <React.Fragment>
                    <span className="text-xs text-primary font-light">
                      {errors.city.message}
                    </span>
                  </React.Fragment>
                ) : null}
              </React.Fragment>
            )}
          />
        </div>
        <div className="flex flex-col space-y-2 ">
          <label className="text-sm font-normal" htmlFor="code">
            Code
          </label>
          <Controller
            name="code"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Please Don't Leave It Blank.",
              },
            }}
            render={({
              field: { onBlur, onChange, value, ref },
              formState: { errors },
            }) => (
              <React.Fragment>
                <input
                  type="text"
                  ref={ref}
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  onBlur={onBlur}
                  className="w-full bg-white  form-input border-white "
                />
                {errors && errors.code && errors.code.message ? (
                  <React.Fragment>
                    <span className="text-xs text-primary font-light">
                      {errors.code.message}
                    </span>
                  </React.Fragment>
                ) : null}
              </React.Fragment>
            )}
          />
        </div>
        <div className="flex flex-col space-y-2 ">
          <label className="text-sm font-normal" htmlFor="calc_shipping_cost">
            calc_shipping_cost
          </label>
          <Controller
            name="calc_shipping_cost"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Please Don't Leave It Blank.",
              },
            }}
            render={({
              field: { onBlur, onChange, value, ref },
              formState: { errors },
            }) => (
              <React.Fragment>
                <input
                  type="number"
                  ref={ref}
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  onBlur={onBlur}
                  className="w-full bg-white  form-input border-white "
                />
                {errors &&
                errors.calc_shipping_cost &&
                errors.calc_shipping_cost.message ? (
                  <React.Fragment>
                    <span className="text-xs text-primary font-light">
                      {errors.calc_shipping_cost.message}
                    </span>
                  </React.Fragment>
                ) : null}
              </React.Fragment>
            )}
          />
        </div>
        <div className="flex flex-col space-y-2 ">
          <label className="text-sm font-normal" htmlFor="calc_shipping_cost">
            calc_shipping_extra
          </label>
          <Controller
            name="calc_shipping_extra"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Please Don't Leave It Blank.",
              },
            }}
            render={({
              field: { onBlur, onChange, value, ref },
              formState: { errors },
            }) => (
              <React.Fragment>
                <input
                  type="number"
                  ref={ref}
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  onBlur={onBlur}
                  className="w-full bg-white  form-input border-white "
                />
                {errors &&
                errors.calc_shipping_extra &&
                errors.calc_shipping_extra.message ? (
                  <React.Fragment>
                    <span className="text-xs text-primary font-light">
                      {errors.calc_shipping_extra.message}
                    </span>
                  </React.Fragment>
                ) : null}
              </React.Fragment>
            )}
          />
        </div>

        <div className="py-2 flex flex-row">
          <button
           
            type="submit"
            className="px-4 font-bold text-sm tracking-widest py-2 bg-primary text-white hover:bg-secondary"
          >
            Save
          </button>
        </div>
      </form>
    </React.Fragment>
  );
};

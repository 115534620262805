import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { UseFormSetValue } from "react-hook-form";
import { Dialog, Transition } from "@headlessui/react";
import { API, APIURLS } from "../../Services";

import { RiCloseLine } from "react-icons/ri";
import { useAuthContext } from "../../ContextProviders/AuthProvider";
interface props {
  setValue: UseFormSetValue<any>;
  name: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
export const AppImagePicker: React.FC<props> = ({
  setValue,
  name,
  open,
  setOpen,
}) => {
  const [files, setFiles] = useState<string[]>([]);
  const {accessToken} = useAuthContext()
  const syncFiles = useCallback(async () => {
    const { data } = await API.get(`${APIURLS.uploads.all}`,{headers:{Authorization:`Bearer ${accessToken}`}});
    const respone: string[] = data;
    setFiles(respone);
  }, []);
  useEffect(() => {
    syncFiles();
  }, [syncFiles]);
  const [searchText, setsearchText] = useState("");
  const filteredFiles = useMemo(() => {
    return files.filter((file) =>
      file
        .replace("https://anmainsushi.s3.eu-central-1.amazonaws.com/", "").toUpperCase()
        .includes(searchText.toUpperCase())
    );
  }, [files, searchText]);
  return (
    <React.Fragment>
      <Transition appear show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setOpen(!open)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full relative max-w-screen-lg transform overflow-hidden rounded-sm bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="div"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    <input
                      type="text"
                      value={searchText}
                      onChange={(e) => setsearchText(e.target.value)}
                      className="form-input w-full max-w-screen-sm"
                      placeholder="search files..."
                    />
                  </Dialog.Title>
                  <button
                    onClick={() => setOpen(!open)}
                    type="button"
                    className="absolute top-0 right-0 px-2 py-2 text-white bg-red-600"
                  >
                    <RiCloseLine className="w-auto h-6" />
                  </button>
                  <div className="mt-4 w-full grid grid-cols-12 gap-3 h-96 overflow-y-scroll ">
                    {filteredFiles.map((f, i) => (
                      <div
                        key={i}
                        className="col-span-4 px-2 py-2   border-slate-100 border"
                      >
                        <img
                          onClick={() => {
                            setValue(name, f);
                            setOpen(!open);
                          }}
                          src={f}
                          alt=""
                          className="w-full h-24 object-contain object-center rounded-md"
                        />
                        <p className="text-black">
                          {f.replace(
                            "https://anmainsushi.s3.eu-central-1.amazonaws.com/",
                            ""
                          )}
                        </p>
                      </div>
                    ))}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </React.Fragment>
  );
};

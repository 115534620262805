import React, { useCallback, useEffect, useState } from "react";
import { couponProps } from "../../../../Interfaces";
import { API, APIURLS } from "../../../../Services";
import { AxiosError, AxiosResponse } from "axios";
import toast from "react-hot-toast";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import { RiDeleteBinLine, RiEditBoxLine, RiMoreLine } from "react-icons/ri";
import { AppNoData } from "../../../Atoms/AppNoData";
import { Link } from "react-router-dom";
import { BsClockHistory, BsToggleOff, BsToggleOn } from "react-icons/bs";
import moment from "moment";
import { useAuthContext } from "../../../../ContextProviders/AuthProvider";
export const AppCouponListComponent: React.FC = () => {
  const [coupons, setcoupons] = useState<couponProps[]>([]);
  const { accessToken } = useAuthContext();
  const syncCoupons = useCallback(() => {
    API.get(`${APIURLS.coupons.all}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
      .then((res: AxiosResponse) => {
        setcoupons(res.data);
      })
      .catch((err: AxiosError) => {
        console.log(err.message);
      });
  }, [accessToken]);

  useEffect(() => {
    syncCoupons();
  }, [syncCoupons]);
  const onDeleteHandler = async (id: number) => {
    try {
      const { data } = await API.delete(`${APIURLS.coupons.delete}/${id}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      if (data) {
        toast.success(`coupon deleted`, {
          icon: <RiDeleteBinLine className="w-auto h-6" />,
        });
        await syncCoupons();
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <React.Fragment>
      {coupons && coupons.length > 0 ? (
        <React.Fragment>
          <div className="w-full px-2 py-2 flex flex-col space-y-2">
            <h4 className="font-bold text-xl text-slate-950">
              {coupons.length} Coupons
            </h4>
          </div>
          <div className="w-full block h-screen">
            <ReactDataGrid
              columns={[
                { name: "code", header: "Code" },
                {
                  name: "type",
                  header: "Type",
                  render: ({ value }) => (
                    <React.Fragment>
                      <span className="text-sm font-bold uppercase">
                        {value === "percentage" ? "Percentage" : "fixed"}
                      </span>
                    </React.Fragment>
                  ),
                },
                { name: "amount", header: "Amount" },

                {
                  name: "is_coupon_amount_on",
                  header: "Amount based?",
                  headerAlign: "center",
                  render: ({ value }) => (
                    <React.Fragment>
                      <div className="w-full flex flex-row justify-center items-center content-center">
                        {Boolean(value) ? (
                          <BsToggleOn className="text-green-600 w-auto h-6 text-center" />
                        ) : (
                          <BsToggleOff className="text-red-600 w-auto h-6 text-center" />
                        )}
                      </div>
                    </React.Fragment>
                  ),
                },
                {
                  name: "is_coupon_schedule",
                  header: "Duration based?",
                  headerAlign: "center",
                  render: ({ value }) => (
                    <React.Fragment>
                      <div className="w-full flex flex-row justify-center items-center content-center">
                        {Boolean(value) ? (
                          <BsToggleOn className="text-green-600 w-auto h-6 text-center" />
                        ) : (
                          <BsToggleOff className="text-red-600 w-auto h-6 text-center" />
                        )}
                      </div>
                    </React.Fragment>
                  ),
                },
                {
                  name: "updated_at",
                  header: <BsClockHistory className="w-auto h-6" />,
                  minWidth: 200,
                  render: ({ value }) => (
                    <React.Fragment>
                      <div className="w-full flex flex-col space-y-0 justify-center items-center content-center">
                        <span className="text-xs font-normal">
                          {moment(new Date(value)).format("YYYY-MM-DD HH:mm A")}
                        </span>
                      </div>
                    </React.Fragment>
                  ),
                  headerAlign: "center",
                },

                {
                  name: "is_active",
                  header: "Published?",
                  headerAlign: "center",
                  render: ({ value }) => (
                    <React.Fragment>
                      <div className="w-full flex flex-row justify-center items-center content-center">
                        {Boolean(value) ? (
                          <BsToggleOn className="text-green-600 w-auto h-6 text-center" />
                        ) : (
                          <BsToggleOff className="text-red-600 w-auto h-6 text-center" />
                        )}
                      </div>
                    </React.Fragment>
                  ),
                },
                {
                  name: "id",
                  header: <RiMoreLine />,
                  minWidth: 200,
                  render: ({ value }) => (
                    <div className="w-full flex flex-row justify-center items-center content-center space-x-4">
                      <Link to={`/dashboard/coupon_update/${value}`}>
                        <RiEditBoxLine className="w-auto h-6 text-yellow-600" />
                      </Link>
                      <button
                        onClick={() => onDeleteHandler(value)}
                        type="button"
                        className="px-2 py-2"
                      >
                        <RiDeleteBinLine className="w-auto h-6 text-primary" />
                      </button>
                    </div>
                  ),
                },
              ]}
              checkboxColumn={true}
              dataSource={coupons}
              idProperty="id"
              showEmptyRows={false}
              pagination={true}
              filterable
              className="w-full overflow-y-auto h-5/6"
            />
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <AppNoData message="No coupons in database." />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

import React, { useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { API, APIURLS } from "../../../../Services";
import toast from "react-hot-toast";
import { AiFillPlusSquare } from "react-icons/ai";
import { useParams } from "react-router-dom";
import { settingProps } from "../../../../Interfaces";
import { useAuthContext } from "../../../../ContextProviders/AuthProvider";

export const AppSettingEditFormComponent: React.FC = () => {
  const { control, handleSubmit, setValue } = useForm({
    mode: "onChange",

    defaultValues: {
      key: "",
      value: "",
      is_image: false,
      is_secure: false,
      group_key: "",
    },
  });
  const { id } = useParams();
  const { accessToken } = useAuthContext();
  const getSetting = useCallback(async () => {
    const { data } = await API.get(`${APIURLS.settings.get}/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    if (data) {
      const setting: settingProps = data;
      setValue("group_key", setting.group_key ? setting.group_key : "");
      setValue("key", setting.key ? setting.key : "");
      setValue("value", setting.value ? setting.value : "");
      setValue("is_image", setting.is_image ? true : false);
      setValue("is_secure", setting.is_secure ? true : false);
    }
  }, [setValue, id,accessToken]);

  useEffect(() => {
    getSetting();
  }, [getSetting]);

  const onSubmit = (data: any) => {
    if (data) {
      API.patch(
        `${APIURLS.settings.update}/${id}`,
        {
          key: data.key ? data.key : "",
          value: data.value ? data.value : "",
          is_secure: Boolean(data.is_secure),
          is_image: Boolean(data.is_image),
          group_key: data.group_key ? data.group_key : "",
        },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
        .then((res) => {
          if (res.data) {
            toast.success(" setting updated.", {
              className: "text-sm font-normal",
              icon: <AiFillPlusSquare className="w-auto h-6" />,
            });
          }
        })
        .catch((err: any) => {
          toast.success(`${JSON.stringify(err.message)}`, {
            className: "text-sm font-normal",
            icon: <AiFillPlusSquare className="w-auto h-6" />,
          });
        })
        .finally(() => {
          console.log(`setting create API done.`);
        });
    }
  };
  return (
    <React.Fragment>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full flex flex-col  max-w-screen-md space-y-2"
      >
        <div className="flex flex-col space-y-2 ">
          <label className="text-sm font-normal" htmlFor="key">
            Key
          </label>
          <Controller
            name="key"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Please Don't Leave It Blank.",
              },
            }}
            render={({
              field: { onBlur, onChange, value, ref },
              formState: { errors },
            }) => (
              <React.Fragment>
                <input
                  type="text"
                  ref={ref}
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  onBlur={onBlur}
                  className="w-full bg-white  form-input border-white "
                />
                {errors && errors.key && errors.key.message ? (
                  <React.Fragment>
                    <span className="text-xs text-primary font-light">
                      {errors.key.message}
                    </span>
                  </React.Fragment>
                ) : null}
              </React.Fragment>
            )}
          />
        </div>
        <div className="flex flex-col space-y-2 ">
          <label className="text-sm font-normal" htmlFor="key">
            Value
          </label>
          <Controller
            name="value"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Please Don't Leave It Blank.",
              },
            }}
            render={({
              field: { onBlur, onChange, value, ref },
              formState: { errors },
            }) => (
              <React.Fragment>
                <textarea
                  ref={ref}
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  onBlur={onBlur}
                  className="w-full bg-white  form-input border-white "
                />
                {errors && errors.value && errors.value.message ? (
                  <React.Fragment>
                    <span className="text-xs text-primary font-light">
                      {errors.value.message}
                    </span>
                  </React.Fragment>
                ) : null}
              </React.Fragment>
            )}
          />
        </div>
        <div className="flex flex-col space-y-2 ">
          <label className="text-sm font-normal" htmlFor="key">
            Group
          </label>
          <Controller
            name="group_key"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Please Don't Leave It Blank.",
              },
            }}
            render={({
              field: { onBlur, onChange, value, ref },
              formState: { errors },
            }) => (
              <React.Fragment>
                <select
                  onBlur={onBlur}
                  ref={ref}
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                >
                  <option value="">please select one.</option>
                  <option value="general">General</option>
                  <option value="pusher">Pusher</option>
                  <option value="payment">Payment</option>
                  <option value="order">Order</option>
                  <option value="product">Prodcut</option>
                  <option value="shipping">Shipping</option>
                </select>
                {errors && errors.group_key && errors.group_key.message ? (
                  <React.Fragment>
                    <span className="text-xs text-primary font-light">
                      {errors.group_key.message}
                    </span>
                  </React.Fragment>
                ) : null}
              </React.Fragment>
            )}
          />
        </div>
        <div className="w-full grid grid-cols-12 gap-3">
          <div className="col-span-12 md:col-span-6">
            <Controller
              name="is_image"
              control={control}
              render={({ field: { onBlur, onChange, ref, value } }) => (
                <React.Fragment>
                  <input
                    type="checkbox"
                    ref={ref}
                    checked={Boolean(value)}
                    onChange={(e) => onChange(e.target.checked)}
                    onBlur={onBlur}
                    className="form-checkbox mr-2"
                  />
                </React.Fragment>
              )}
            />
            <label
              htmlFor="is_image"
              className="text-sm font-normal text-slate-700"
            >
              Is Image?
            </label>
          </div>
          <div className="col-span-12 md:col-span-6">
            <Controller
              name="is_secure"
              control={control}
              render={({ field: { onBlur, onChange, ref, value } }) => (
                <React.Fragment>
                  <input
                    type="checkbox"
                    ref={ref}
                    checked={Boolean(value)}
                    onChange={(e) => onChange(e.target.checked)}
                    onBlur={onBlur}
                    className="form-checkbox mr-2"
                  />
                </React.Fragment>
              )}
            />
            <label
              htmlFor="is_secure"
              className="text-sm font-normal text-slate-700"
            >
              Is Secure?
            </label>
          </div>
        </div>
        <div className="py-2 flex flex-row">
          <button
            type="submit"
            className="px-4 font-bold text-sm tracking-widest py-2 bg-primary text-white hover:bg-secondary"
          >
            Save
          </button>
        </div>
      </form>
    </React.Fragment>
  );
};

import React, { useCallback, useEffect, useState } from "react";
import { settingProps } from "../../../../Interfaces";
import { API, APIURLS } from "../../../../Services";
import { AppNoData } from "../../../Atoms/AppNoData";
import { groupBy } from "lodash";
import toast from "react-hot-toast";
import { RiDeleteBinLine, RiEditBoxLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { BsKeyFill } from "react-icons/bs";
import { useAuthContext } from "../../../../ContextProviders/AuthProvider";

export const AppSettingListComponent: React.FC = () => {
  const [settings, setsettings] = useState<settingProps[]>([]);
 const {accessToken} =  useAuthContext()
  const syncSetting = useCallback(async () => {
    const { data } = await API.get(`${APIURLS.settings.all}`,{headers:{'Authorization':`Bearer ${accessToken}`}});
    if (data) {
      const response: settingProps[] = data;
      setsettings(response);
    }
  }, [accessToken]);

  useEffect(() => {
    syncSetting();
  }, [syncSetting]);

  const onDeleteHandler = async (id: number) => {
    try {
      const { data } = await API.delete(`${APIURLS.settings.delete}/${id}`,{headers:{'Authorization':`Bearer ${accessToken}`}});
      if (data) {
        toast.success(`setting deleted`, {
          icon: <RiDeleteBinLine className="w-auto h-6" />,
        });
        await syncSetting();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      {settings && settings.length > 0 ? (
        <React.Fragment>
          {Object.keys(groupBy(settings, "group_key")).map((key, index) => (
            <div
              className="w-full flex flex-col space-x-2 my-2 py-2 px-2 border-b border-white"
              key={index}
            >
              <h6 className="text-lg font-bold tracking-wider text-slate-950 uppercase">
                {key}
              </h6>
              {groupBy(settings, "group_key")[key].map(
                (setting: settingProps, i: number) => (
                  <div
                    key={i}
                    className="w-full grid grid-cols-12 gap-3 max-w-screen-xl  my-2 px-2 py-2"
                  >
                    <div className="col-span-12 md:col-span-3">
                      <div className="flex flex-row justify-start items-center content-center space-x-2">
                        {Boolean(setting.is_secure) ? (
                          <BsKeyFill className="w-auto h-4 text-red-500" />
                        ) : (
                          <BsKeyFill className="w-auto h-4 text-green-500" />
                        )}
                        <span className="text-sm font-light text-slate-600">
                          {setting.key}
                        </span>
                      </div>
                    </div>
                    <div className="col-span-12 md:col-span-5">
                      <div className="flex flex-row justify-start items-center content-center space-x-2">
                        {setting.is_image ? (
                          <React.Fragment>
                            <img
                              src={setting.value}
                              className="w-auto h-12 rounded-md"
                              alt=""
                            />
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                           <input type="text" className="w-full text-xs" readOnly value={setting.value?setting.value:""} />
                          </React.Fragment>
                        )}
                      </div>
                    </div>

                    <div className="col-span-12 md:col-span-4">
                      <div className="w-full flex flex-row justify-center items-center content-center space-x-4">
                        <Link to={`/dashboard/setting_update/${setting.id}`}>
                          <RiEditBoxLine className="w-auto h-6 text-yellow-600" />
                        </Link>
                        <button
                          onClick={() => onDeleteHandler(setting.id)}
                          type="button"
                          className="px-2 py-2"
                        >
                          <RiDeleteBinLine className="w-auto h-6 text-primary" />
                        </button>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          ))}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <AppNoData message="No settings in database." />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

import React, { useCallback, useEffect, useState } from "react";
import { UseFormSetValue } from "react-hook-form";
import Select from "react-select";
import { API, APIURLS } from "../../Services";
import { categoryProps } from "../../Interfaces";
interface props {
  setValue: UseFormSetValue<any>;
  value:categoryProps[]
}
export const AppCategoryPicker: React.FC<props> = ({setValue,value}) => {
  const [categories, setcategories] = useState<categoryProps[]>([]);
  const getCategories = useCallback(async () => {
    const { data } = await API.get(`${APIURLS.categories.all}`);
    if (data) {
      setcategories(data);
    }
  }, []);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  return (
    <React.Fragment>
      <div className="w-full flex flex-col space-y-2">
        <p className="text-lg font-bold">Categories</p>
        <Select
          isMulti={true}
          value={value}
          options={categories}
          getOptionValue={(option) => String(option.id)}
          getOptionLabel={(option) => option.name}
          onChange={(value)=>setValue('categories',value.map((value:any)=>value.id))}
        />
      </div>
    </React.Fragment>
  );
};

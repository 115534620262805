import React, { useState } from "react";
import { userProps } from "../Interfaces";

interface authContextType {
  user?: userProps;
  isAuth: boolean;
  setUser: React.Dispatch<React.SetStateAction<userProps | undefined>>;
  setIsAuth: React.Dispatch<React.SetStateAction<boolean>>;
  isNotAdmin: boolean;
  setIsNotAdmin: React.Dispatch<React.SetStateAction<boolean>>;
  accessToken:string
  setAccessToken:React.Dispatch<React.SetStateAction<string>>
}

const AuthContext = React.createContext<authContextType | undefined>(undefined);

export const useAuthContext = (): authContextType => {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error(`useAuthContext must be within a AuthContextProvider`);
  } else {
    return context;
  }
};

interface props {
  children: React.ReactNode;
}

export const AppAuthContextProvider: React.FC<props> = ({ children }) => {
  const [isAuth, setisAuth] = useState(true);
  const [user, setuser] = useState<userProps>();
  const [isNotAdmin, setisNotAdmin] = useState(false);
  const [accessToken, setaccessToken] = useState<string>(localStorage.getItem('sushi_auth_token')||"")
  return (
    <React.Fragment>
      <AuthContext.Provider
        value={{
          isAuth: isAuth,
          setIsAuth: setisAuth,
          user: user,
          setUser: setuser,
          isNotAdmin: isNotAdmin,
          setIsNotAdmin: setisNotAdmin,
          accessToken:accessToken,
          setAccessToken:setaccessToken
        }}
      >
        {children}
      </AuthContext.Provider>
    </React.Fragment>
  );
};

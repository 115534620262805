import React from "react";
import { AppAuthWrapper } from "../Components/Modules/AppAuthWrapper";
import { AppLoginFormComponent } from "../Components/Organisms/Auth/AppLoginForm";
import { useAuthContext } from "../ContextProviders/AuthProvider";
import { Navigate } from "react-router-dom";

export const AppLoginScreen: React.FC = () => {
  const { isAuth } = useAuthContext();

  if (isAuth) {
    return <Navigate to="/dashboard"  />;
  }
  return (
    <React.Fragment>
      <AppAuthWrapper>
        <AppLoginFormComponent />
      </AppAuthWrapper>
    </React.Fragment>
  );
};

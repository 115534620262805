import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { API, APIURLS } from "../../../../Services";
import { CiSquarePlus, CiSquareQuestion } from "react-icons/ci";
import toast from "react-hot-toast";
import { AppImagePicker } from "../../../Atoms/AppImagePicker";
import { AppCategoryPicker } from "../../../Atoms/AppCategoryPicker";
import { productFormDataProps } from "../../../../Interfaces/form";
import { useAuthContext } from "../../../../ContextProviders/AuthProvider";

export const AppProductCreateFormComonent: React.FC = () => {
  const [open, setopen] = useState(false);
  const {accessToken} = useAuthContext()
  const {
    control,
    handleSubmit,
    setValue,
   
  } = useForm({
    mode: "onChange",
    defaultValues: {
      title: "",
      img: "",
      short_description: "",
      description: "",
      _regular_price: 0,
      _sale_price: 0,
      _sale_price_dates_from: "",
      _sale_price_dates_to: "",
      is_sale_schedule: false,
      _tax_status: "taxable",
      _tax_class: "standard",
      _wt_disabled_for_coupons: false,
      sku: 0,
      _manage_stock: false,
      _stock_status: "instock",
      _sold_individually: false,
      _weight: 0,
      product_length: 0,
      product_width: 0,
      product_height: 0,
      _purchase_note: "",
      menu_order: 0,
      categoryIds: [],
      is_active: true,
    },
  });

  const onSubmit = (data: any) => {
    if (data) {
      const formData: productFormDataProps = data;
      const {
        _regular_price,
        _sale_price,
        sku,
        _sale_price_dates_from,
        _sale_price_dates_to,
        _weight,
        product_height,
        product_length,
        product_width,
        categoryIds,
        ...otherData
      } = formData;
      const postData = {
        _regular_price: Number(_regular_price),
        _sale_price: Number(_sale_price),
        sku: Number(sku),
        _weight: Number(_weight),
        product_height: Number(product_height),
        product_length: Number(product_length),
        product_width: Number(product_width),
        _sale_price_dates_from: _sale_price_dates_from
          ? new Date(_sale_price_dates_from).toISOString()
          : new Date().toISOString(),
        _sale_price_dates_to: _sale_price_dates_from
          ? new Date(_sale_price_dates_to).toISOString()
          : new Date().toISOString(),
          categoryIds:categoryIds.map((c)=>c.id),
        ...otherData,
      };
      // console.log(postData,"post")
      API.post(`${APIURLS.products.create}`, postData,{headers:{'Authorization':`Bearer ${accessToken}`}})
        .then((res) => {
          if (res.data) {
            toast.success("New Product created.", {
              className: "text-sm font-normal",
              icon: <CiSquarePlus className="w-auto h-6" />,
            });
          }
        })
        .catch((err: any) => {
          toast.success(`${JSON.stringify(err.message)}`, {
            className: "text-sm font-normal",
            icon: <CiSquareQuestion className="w-auto h-6" />,
          });
        })
        .finally(() => {
          console.log(`Product create API done.`);
        });
    }
  };
  return (
    <React.Fragment>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full flex flex-col  max-w-screen-xl mx-auto space-y-2"
      >
        <div className="w-full grid grid-cols-12 gap-4">
          <div className="col-span-12 md:col-span-8">
            <div className="flex flex-col space-y-2 ">
              <label className="text-sm font-normal" htmlFor="last_name">
                Title
              </label>
              <Controller
                name="title"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Please Don't Leave It Blank.",
                  },
                }}
                render={({
                  field: { onBlur, onChange, value, ref },
                  formState: { errors },
                }) => (
                  <React.Fragment>
                    <input
                      type="text"
                      ref={ref}
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      onBlur={onBlur}
                      className="w-full bg-white  form-input border-white "
                    />
                    {errors && errors.title && errors.title.message ? (
                      <React.Fragment>
                        <span className="text-xs text-primary font-light">
                          {errors.title.message}
                        </span>
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                )}
              />
            </div>
            <div className="flex flex-col space-y-2 ">
              <label className="text-sm font-normal" htmlFor="last_name">
                Short Description
              </label>
              <Controller
                name="short_description"
                control={control}
                render={({
                  field: { onBlur, onChange, value, ref },
                  formState: { errors },
                }) => (
                  <React.Fragment>
                    <textarea
                      ref={ref}
                      rows={4}
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      onBlur={onBlur}
                      className="w-full bg-white  form-input border-white "
                    />
                    {errors &&
                    errors.short_description &&
                    errors.short_description.message ? (
                      <React.Fragment>
                        <span className="text-xs text-primary font-light">
                          {errors.short_description.message}
                        </span>
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                )}
              />
            </div>
            <div className="flex flex-col space-y-2 ">
              <label className="text-sm font-normal" htmlFor="description">
                Description
              </label>
              <Controller
                name="description"
                control={control}
                rules={{}}
                render={({
                  field: { onBlur, onChange, value, ref },
                  formState: { errors },
                }) => (
                  <React.Fragment>
                    <textarea
                      ref={ref}
                      rows={4}
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      onBlur={onBlur}
                      className="w-full bg-white  form-input border-white "
                    />
                    {errors &&
                    errors.description &&
                    errors.description.message ? (
                      <React.Fragment>
                        <span className="text-xs text-primary font-light">
                          {errors.description.message}
                        </span>
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                )}
              />
            </div>
            <div className="flex flex-col space-y-2 ">
              <label className="text-sm font-normal" htmlFor="_regular_price">
                _regular_price
              </label>
              <Controller
                name="_regular_price"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Please Don't Leave It Blank.",
                  },
                }}
                render={({
                  field: { onBlur, onChange, value, ref },
                  formState: { errors },
                }) => (
                  <React.Fragment>
                    <input
                      type="number"
                      ref={ref}
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      onBlur={onBlur}
                      className="w-full bg-white  form-input border-white "
                    />
                    {errors &&
                    errors._regular_price &&
                    errors._regular_price.message ? (
                      <React.Fragment>
                        <span className="text-xs text-primary font-light">
                          {errors._regular_price.message}
                        </span>
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                )}
              />
            </div>
            <div className="flex flex-col space-y-2 ">
              <label className="text-sm font-normal" htmlFor="_sale_price">
                _sale_price
              </label>
              <Controller
                name="_sale_price"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Please Don't Leave It Blank.",
                  },
                }}
                render={({
                  field: { onBlur, onChange, value, ref },
                  formState: { errors },
                }) => (
                  <React.Fragment>
                    <input
                      type="number"
                      ref={ref}
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      onBlur={onBlur}
                      className="w-full bg-white  form-input border-white "
                    />
                    {errors &&
                    errors._sale_price &&
                    errors._sale_price.message ? (
                      <React.Fragment>
                        <span className="text-xs text-primary font-light">
                          {errors._sale_price.message}
                        </span>
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                )}
              />
            </div>
            <div className="flex flex-row my-2 space-x-2 justify-start items-center content-center">
              <Controller
                name="is_sale_schedule"
                control={control}
                render={({
                  field: { onBlur, onChange, ref, value },
                  formState: { isValid },
                }) => (
                  <React.Fragment>
                    <div className="w-full flex flex-col space-y-2">
                      <div className="w-full flex flex-row ">
                        <label
                          ref={ref}
                          htmlFor="is_sale_schedule"
                          className="text-sm font-normal text-slate-700"
                        >
                          <input
                            type="checkbox"
                            value={String(value)}
                            checked={value}
                            onChange={(e) => onChange(e.target.checked)}
                            onBlur={onBlur}
                            className="form-checkbox mr-2"
                          />
                          is_sale_schedule ?
                        </label>
                      </div>
                      {value ? (
                        <React.Fragment>
                          <div className="w-full grid grid-cols-12 gap-3 ">
                            <div className="col-span-12 md:col-span-6">
                              <div className="flex flex-col space-y-2 ">
                                <label
                                  className="text-sm font-normal"
                                  htmlFor="_sale_price_dates_from"
                                >
                                  _sale_price_dates_from
                                </label>
                                <Controller
                                  name="_sale_price_dates_from"
                                  control={control}
                                  rules={{
                                    required: {
                                      value: true,
                                      message: "Please Don't Leave It Blank.",
                                    },
                                  }}
                                  render={({
                                    field: { onBlur, onChange, value, ref },
                                    formState: { errors },
                                  }) => (
                                    <React.Fragment>
                                      <input
                                        type="date"
                                        ref={ref}
                                        value={value}
                                        onChange={(e) =>
                                          onChange(e.target.value)
                                        }
                                        onBlur={onBlur}
                                        className="w-full bg-white  form-input border-white "
                                      />
                                      {errors &&
                                      errors._sale_price_dates_from &&
                                      errors._sale_price_dates_from.message ? (
                                        <React.Fragment>
                                          <span className="text-xs text-primary font-light">
                                            {
                                              errors._sale_price_dates_from
                                                .message
                                            }
                                          </span>
                                        </React.Fragment>
                                      ) : null}
                                    </React.Fragment>
                                  )}
                                />
                              </div>
                            </div>
                            <div className="col-span-12 md:col-span-6">
                              <div className="flex flex-col space-y-2 ">
                                <label
                                  className="text-sm font-normal"
                                  htmlFor="_sale_price_dates_from"
                                >
                                  _sale_price_dates_to
                                </label>
                                <Controller
                                  name="_sale_price_dates_to"
                                  control={control}
                                  rules={{
                                    required: {
                                      value: true,
                                      message: "Please Don't Leave It Blank.",
                                    },
                                  }}
                                  render={({
                                    field: { onBlur, onChange, value, ref },
                                    formState: { errors },
                                  }) => (
                                    <React.Fragment>
                                      <input
                                        type="date"
                                        ref={ref}
                                        value={value}
                                        onChange={(e) =>
                                          onChange(e.target.value)
                                        }
                                        onBlur={onBlur}
                                        className="w-full bg-white  form-input border-white "
                                      />
                                      {errors &&
                                      errors._sale_price_dates_to &&
                                      errors._sale_price_dates_to.message ? (
                                        <React.Fragment>
                                          <span className="text-xs text-primary font-light">
                                            {
                                              errors._sale_price_dates_to
                                                .message
                                            }
                                          </span>
                                        </React.Fragment>
                                      ) : null}
                                    </React.Fragment>
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      ) : null}
                    </div>
                  </React.Fragment>
                )}
              />
            </div>
            <div className="flex flex-col space-y-2 ">
              <label className="text-sm font-normal" htmlFor="_tax_status">
                _tax_status
              </label>
              <Controller
                name="_tax_status"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Please Don't Leave It Blank.",
                  },
                }}
                render={({
                  field: { onBlur, onChange, value, ref },
                  formState: { errors },
                }) => (
                  <React.Fragment>
                    <select
                      className="w-full form-select"
                      ref={ref}
                      value={value}
                      onBlur={onBlur}
                      onChange={(e) => onChange(e.target.value)}
                    >
                      <option value="taxable">taxable</option>
                      <option value="shipping">shipping</option>
                      <option value="none">none</option>
                    </select>
                    {errors &&
                    errors._tax_status &&
                    errors._tax_status.message ? (
                      <React.Fragment>
                        <span className="text-xs text-primary font-light">
                          {errors._tax_status.message}
                        </span>
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                )}
              />
            </div>
            <div className="flex flex-col space-y-2 ">
              <label className="text-sm font-normal" htmlFor="_tax_class">
                _tax_class
              </label>
              <Controller
                name="_tax_class"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Please Don't Leave It Blank.",
                  },
                }}
                render={({
                  field: { onBlur, onChange, value, ref },
                  formState: { errors },
                }) => (
                  <React.Fragment>
                    <select
                      className="w-full form-select"
                      ref={ref}
                      value={value}
                      onBlur={onBlur}
                      onChange={(e) => onChange(e.target.value)}
                    >
                      <option value="standard">standard</option>
                      <option value="reduced-rate">reduced-rate</option>
                      <option value="virtual-rate">virtual-rate</option>
                      <option value="virtual-reduced-rate">
                        virtual-reduced-rate
                      </option>
                      <option value="zero-rate">zero-rate</option>
                    </select>
                    {errors &&
                    errors._tax_class &&
                    errors._tax_class.message ? (
                      <React.Fragment>
                        <span className="text-xs text-primary font-light">
                          {errors._tax_class.message}
                        </span>
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                )}
              />
            </div>
            <div className="flex flex-col space-y-2 ">
              <label className="text-sm font-normal" htmlFor="_tax_class">
                Attributes
              </label>
              <div className="w-full grid grid-cols-12 gap-3">
                <div className="col-span-12 md:col-span-3">
                  <div className="flex flex-col space-y-2 ">
                    <label className="text-sm font-normal" htmlFor="_weight">
                      _weight
                    </label>
                    <Controller
                      name="_weight"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: "Please Don't Leave It Blank.",
                        },
                      }}
                      render={({
                        field: { onBlur, onChange, value, ref },
                        formState: { errors },
                      }) => (
                        <React.Fragment>
                          <input
                            type="number"
                            ref={ref}
                            value={value}
                            onChange={(e) => onChange(e.target.value)}
                            onBlur={onBlur}
                            className="w-full bg-white  form-input border-white "
                          />
                          {errors &&
                          errors._weight &&
                          errors._weight.message ? (
                            <React.Fragment>
                              <span className="text-xs text-primary font-light">
                                {errors._weight.message}
                              </span>
                            </React.Fragment>
                          ) : null}
                        </React.Fragment>
                      )}
                    />
                  </div>
                </div>
                <div className="col-span-12 md:col-span-3">
                  <div className="flex flex-col space-y-2 ">
                    <label className="text-sm font-normal" htmlFor="_weight">
                      product_length
                    </label>
                    <Controller
                      name="product_length"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: "Please Don't Leave It Blank.",
                        },
                      }}
                      render={({
                        field: { onBlur, onChange, value, ref },
                        formState: { errors },
                      }) => (
                        <React.Fragment>
                          <input
                            type="number"
                            ref={ref}
                            value={value}
                            onChange={(e) => onChange(e.target.value)}
                            onBlur={onBlur}
                            className="w-full bg-white  form-input border-white "
                          />
                          {errors &&
                          errors.product_length &&
                          errors.product_length.message ? (
                            <React.Fragment>
                              <span className="text-xs text-primary font-light">
                                {errors.product_length.message}
                              </span>
                            </React.Fragment>
                          ) : null}
                        </React.Fragment>
                      )}
                    />
                  </div>
                </div>
                <div className="col-span-12 md:col-span-3">
                  <div className="flex flex-col space-y-2 ">
                    <label className="text-sm font-normal" htmlFor="_weight">
                      product_width
                    </label>
                    <Controller
                      name="product_width"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: "Please Don't Leave It Blank.",
                        },
                      }}
                      render={({
                        field: { onBlur, onChange, value, ref },
                        formState: { errors },
                      }) => (
                        <React.Fragment>
                          <input
                            type="number"
                            ref={ref}
                            value={value}
                            onChange={(e) => onChange(e.target.value)}
                            onBlur={onBlur}
                            className="w-full bg-white  form-input border-white "
                          />
                          {errors &&
                          errors.product_width &&
                          errors.product_width.message ? (
                            <React.Fragment>
                              <span className="text-xs text-primary font-light">
                                {errors.product_width.message}
                              </span>
                            </React.Fragment>
                          ) : null}
                        </React.Fragment>
                      )}
                    />
                  </div>
                </div>
                <div className="col-span-12 md:col-span-3">
                  <div className="flex flex-col space-y-2 ">
                    <label className="text-sm font-normal" htmlFor="_weight">
                      product_height
                    </label>
                    <Controller
                      name="product_height"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: "Please Don't Leave It Blank.",
                        },
                      }}
                      render={({
                        field: { onBlur, onChange, value, ref },
                        formState: { errors },
                      }) => (
                        <React.Fragment>
                          <input
                            type="number"
                            ref={ref}
                            value={value}
                            onChange={(e) => onChange(e.target.value)}
                            onBlur={onBlur}
                            className="w-full bg-white  form-input border-white "
                          />
                          {errors &&
                          errors.product_height &&
                          errors.product_height.message ? (
                            <React.Fragment>
                              <span className="text-xs text-primary font-light">
                                {errors.product_height.message}
                              </span>
                            </React.Fragment>
                          ) : null}
                        </React.Fragment>
                      )}
                    />
                  </div>
                </div>
                <div className="col-span-12 md:col-span-12">
                  <div className="flex flex-col space-y-2 ">
                    <label className="text-sm font-normal" htmlFor="_weight">
                      _purchase_note
                    </label>
                    <Controller
                      name="_purchase_note"
                      control={control}
                      render={({
                        field: { onBlur, onChange, value, ref },
                        formState: { errors },
                      }) => (
                        <React.Fragment>
                          <textarea
                            rows={6}
                            ref={ref}
                            value={value}
                            onChange={(e) => onChange(e.target.value)}
                            onBlur={onBlur}
                            className="w-full bg-white  form-input border-white "
                          />
                          {errors &&
                          errors._purchase_note &&
                          errors._purchase_note.message ? (
                            <React.Fragment>
                              <span className="text-xs text-primary font-light">
                                {errors._purchase_note.message}
                              </span>
                            </React.Fragment>
                          ) : null}
                        </React.Fragment>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-12 md:col-span-4">
            <div className="flex flex-col space-y-2 ">
              <label className="text-sm font-normal" htmlFor="img">
                Image
              </label>
              <Controller
                name="img"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Please Don't Leave It Blank.",
                  },
                }}
                render={({
                  field: { onBlur, value, ref },
                  formState: { errors },
                }) => (
                  <React.Fragment>
                    <img
                      onBlur={onBlur}
                      ref={ref}
                      onClick={() => setopen(!open)}
                      src={value}
                      alt="img"
                      className="w-auto object-contain object-center h-32 select-none border border-slate-300 rounded-md"
                    />
                    <AppImagePicker
                      open={open}
                      setOpen={setopen}
                      setValue={setValue}
                      name="img"
                    />
                    {errors && errors.img && errors.img.message ? (
                      <React.Fragment>
                        <span className="text-xs text-primary font-light">
                          {errors.img.message}
                        </span>
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                )}
              />
            </div>
            <div className="flex flex-row my-2 ">
              <Controller
                name="_wt_disabled_for_coupons"
                control={control}
                render={({
                  field: { onBlur, onChange, value, ref },
                  formState: { errors },
                }) => (
                  <React.Fragment>
                    <input
                      ref={ref}
                      type="checkbox"
                      value={String(value)}
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                      onBlur={onBlur}
                      className="form-checkbox mr-2"
                    />

                    {errors &&
                    errors._wt_disabled_for_coupons &&
                    errors._wt_disabled_for_coupons.message ? (
                      <React.Fragment>
                        <span className="text-xs text-primary font-light">
                          {errors._wt_disabled_for_coupons.message}
                        </span>
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                )}
              />
              <label className="text-sm font-normal" htmlFor="_tax_class">
                _wt_disabled_for_coupons
              </label>
            </div>
            <div className="flex flex-col space-y-2 ">
              <label className="text-sm font-normal" htmlFor="last_name">
                Sku
              </label>
              <Controller
                name="sku"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Please Don't Leave It Blank.",
                  },
                }}
                render={({
                  field: { onBlur, onChange, value, ref },
                  formState: { errors },
                }) => (
                  <React.Fragment>
                    <input
                      type="number"
                      ref={ref}
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      onBlur={onBlur}
                      className="w-full bg-white  form-input border-white "
                    />
                    {errors && errors.sku && errors.sku.message ? (
                      <React.Fragment>
                        <span className="text-xs text-primary font-light">
                          {errors.sku.message}
                        </span>
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                )}
              />
            </div>
            <div className="flex flex-row my-2 ">
              <Controller
                name="_manage_stock"
                control={control}
                rules={{}}
                render={({
                  field: { onBlur, onChange, value, ref },
                  formState: { errors },
                }) => (
                  <React.Fragment>
                    <input
                      ref={ref}
                      type="checkbox"
                      value={String(value)}
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                      onBlur={onBlur}
                      className="form-checkbox mr-2"
                    />

                    {errors &&
                    errors._manage_stock &&
                    errors._manage_stock.message ? (
                      <React.Fragment>
                        <span className="text-xs text-primary font-light">
                          {errors._manage_stock.message}
                        </span>
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                )}
              />
              <label className="text-sm font-normal" htmlFor="_tax_class">
                _manage_stock
              </label>
            </div>
            <div className="flex flex-col space-y-2 ">
              <label className="text-sm font-normal" htmlFor="_tax_status">
                _stock_status
              </label>
              <Controller
                name="_stock_status"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Please Don't Leave It Blank.",
                  },
                }}
                render={({
                  field: { onBlur, onChange, value, ref },
                  formState: { errors },
                }) => (
                  <React.Fragment>
                    <select
                      className="w-full form-select"
                      ref={ref}
                      value={value}
                      onBlur={onBlur}
                      onChange={(e) => onChange(e.target.value)}
                    >
                      <option value="instock">instock</option>
                      <option value="outofstock">outofstock</option>
                      <option value="onbackorder">onbackorder</option>
                    </select>
                    {errors &&
                    errors._stock_status &&
                    errors._stock_status.message ? (
                      <React.Fragment>
                        <span className="text-xs text-primary font-light">
                          {errors._stock_status.message}
                        </span>
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                )}
              />
            </div>
            <div className="flex flex-row my-2 ">
              <Controller
                name="_sold_individually"
                control={control}
                rules={{}}
                render={({
                  field: { onBlur, onChange, value, ref },
                  formState: { errors },
                }) => (
                  <React.Fragment>
                    <input
                      ref={ref}
                      type="checkbox"
                      value={String(value)}
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                      onBlur={onBlur}
                      className="form-checkbox mr-2"
                    />

                    {errors &&
                    errors._sold_individually &&
                    errors._sold_individually.message ? (
                      <React.Fragment>
                        <span className="text-xs text-primary font-light">
                          {errors._sold_individually.message}
                        </span>
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                )}
              />
              <label className="text-sm font-normal" htmlFor="_tax_class">
                _sold_individually
              </label>
            </div>
            <AppCategoryPicker value={[]} setValue={setValue} />
            <div className="py-2 flex w-full flex-col">
              <button
               
                type="submit"
                className="px-4 w-full font-bold text-sm tracking-widest py-2 bg-primary text-white hover:bg-secondary"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};

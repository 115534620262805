import React, { useCallback, useEffect, useState } from "react";
import { langProps } from "../../../../Interfaces";
import { API, APIURLS } from "../../../../Services";
import toast from "react-hot-toast";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import { RiDeleteBinLine, RiEditBoxLine, RiMoreLine } from "react-icons/ri";
import { AppNoData } from "../../../Atoms/AppNoData";
import { Link } from "react-router-dom";
import { BsClockHistory } from "react-icons/bs";
import moment from "moment";
import { useAuthContext } from "../../../../ContextProviders/AuthProvider";
export const AppLanguageListComponent: React.FC = () => {
  const [langs, setLangs] = useState<langProps[]>([]);
   const {accessToken} = useAuthContext()
  const syncList = useCallback(async () => {
    try {
      const response = await API.get(`${APIURLS.languages.all}`,{headers:{'Authorization':`Bearer ${accessToken}`}});
      const listReponse: langProps[] = response.data;
      setLangs(listReponse);
    } catch (error) {
      console.log(`API error ${error}`);
    }
  }, [accessToken]);
  useEffect(() => {
    syncList();
  }, [syncList]);

  const onDeleteHandler = async (id: number) => {
    try {
      const { data } = await API.delete(`${APIURLS.languages.delete}/${id}`,{headers:{'Authorization':`Bearer ${accessToken}`}});
      if (data) {
        toast.success(`translation deleted`, {
          icon: <RiDeleteBinLine className="w-auto h-6" />,
        });
        await syncList();
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <React.Fragment>
      {langs && langs.length > 0 ? (
        <React.Fragment>
          <div className="w-full px-2 py-2 flex flex-col space-y-2">
            <h4 className="font-bold text-xl text-slate-950">
              {langs.length} Translation
            </h4>
          </div>
          <div className="w-full block h-screen">
            <ReactDataGrid
              columns={[
                {
                  name: "key",
                  header: "Key",
                },
                {
                  name: "en",
                  header: "English",
                  width: 200,
                },

                {
                  name: "de",
                  header: "Deutschland",
                  width: 200,
                },

                {
                  name: "updated_at",
                  header: <BsClockHistory className="w-auto h-6" />,
                  minWidth: 200,
                  render: ({ value }) => (
                    <React.Fragment>
                      <div className="w-full flex flex-col space-y-0 justify-center items-center content-center">
                        <span className="text-xs font-normal">
                          {moment(new Date(value)).format("YYYY-MM-DD")}(
                          {moment(new Date(value)).fromNow()})
                        </span>
                      </div>
                    </React.Fragment>
                  ),
                  headerAlign: "center",
                },

                {
                  name: "id",
                  header: <RiMoreLine />,
                  minWidth: 200,
                  render: ({ value }) => (
                    <div className="w-full flex flex-row justify-center items-center content-center space-x-4">
                      <Link to={`/dashboard/language_update/${value}`}>
                        <RiEditBoxLine className="w-auto h-6 text-yellow-600" />
                      </Link>
                      <button
                        onClick={() => onDeleteHandler(value)}
                        type="button"
                        className="px-2 py-2"
                      >
                        <RiDeleteBinLine className="w-auto h-6 text-primary" />
                      </button>
                    </div>
                  ),
                },
              ]}
              checkboxColumn={true}
              idProperty="id"
              showEmptyRows={false}
              dataSource={langs}
              className="w-full overflow-y-auto h-5/6"
              pagination={true}
              filterable
            />
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <AppNoData message="No translations in database." />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

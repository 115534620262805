import React, { ChangeEvent, useState } from "react";
import toast from "react-hot-toast";
import { AiFillExclamationCircle, AiOutlineUpload } from "react-icons/ai";
import { API, APIURLS } from "../../../../Services";
import { useAuthContext } from "../../../../ContextProviders/AuthProvider";

export const AppFileUploadFormComponent: React.FC = () => {
  const [files, setFiles] = useState<FileList | undefined>(undefined);
  const {accessToken} = useAuthContext()
  const SubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();

      const formData = new FormData();

      if (files && files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          formData.append("file", files[i]);
        }
      }
      API.post(`${APIURLS.uploads.createObjects}`, formData, {
        headers: { "Content-Type": "multipart/form-data","Authorization":`Bearer ${accessToken}` },
      })
        .then((res) => {
          if (res.data) {
            toast.success("New file created.", {
              className: "text-sm font-normal",
              icon: <AiOutlineUpload className="w-auto h-6" />,
            });
          }
        })
        .catch((err: any) => {
          console.log(err);
        })
        .finally(() => {
          console.log(`tanslation create API done.`);
        });
    } catch (error: any) {
      toast.error(error.message, {
        className: "text-sm font-normal",
        icon: <AiFillExclamationCircle className="w-auto h-6" />,
      });
    }
  };
  return (
    <React.Fragment>
      <form
        className="w-full flex flex-col max-w-screen-md  space-y-2"
        onSubmit={(e: React.FormEvent<HTMLFormElement>) => SubmitForm(e)}
      >
        <div className="flex flex-col space-y-2 ">
          <input
            multiple
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setFiles(e.target && e.target.files ? e.target.files : undefined)
            }
            type="file"
            className="form-input w-full "
          />
        </div>
        <div className="py-2 flex flex-row">
          <button
            disabled={files === undefined}
            type="submit"
            className="px-4 font-bold text-sm tracking-widest py-2 bg-primary text-white hover:bg-secondary"
          >
            Upload
          </button>
        </div>
      </form>
    </React.Fragment>
  );
};

import React from "react";
import { AppDashbarodHomeComponent } from "../../Components/Organisms/Dashbaord/Home/AppDashboardHome";

export const AppDashbaordHomeScreen: React.FC = () => {
  return (
    <React.Fragment>
      <AppDashbarodHomeComponent />
    </React.Fragment>
  );
};

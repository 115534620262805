import React from "react";
import { orderProps } from "../../Interfaces";
import { AppOrderCard } from "./AppOrderCard";
interface props {
  orders: orderProps[];
  header: string;
}
export const AppOrderList: React.FC<props> = ({ header, orders }) => {
  return (
    <React.Fragment>
      <div className="w-full flex flex-col px-2 py-2">
        <div className="w-full px-2 py-2 bg-black text-white text-center">
          <h6 className="text-sm uppercase font-bold">{header}</h6>
        </div>
        <div className="flex w-full flex-col space-y-2 py-3">
          {orders.map((order, i) => (
            <AppOrderCard key={i} order={order} />
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

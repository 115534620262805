import React, { useCallback, useEffect, useState } from "react";
import { orderProps } from "../../../../Interfaces";
import { useParams } from "react-router-dom";
import { API, APIURLS } from "../../../../Services";
import { AxiosResponse } from "axios";

import { useAuthContext } from "../../../../ContextProviders/AuthProvider";

import { fCurrency } from "../../../../Helpers";
export const AppOrderViewComponent: React.FC = () => {
  const [order, setorder] = useState<orderProps>();
  const { id } = useParams();
  const { accessToken } = useAuthContext();
  const syncOrder = useCallback(() => {
    API.get(`${APIURLS.orders.get}/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
      .then((res: AxiosResponse) => {
        if (res.data) {
          setorder(res.data);
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, [id, accessToken]);

  useEffect(() => {
    syncOrder();
  }, [syncOrder]);

  return (
    <React.Fragment>
      <React.Fragment>
        {order ? (
          <React.Fragment>
            <div className="w-full my-6 mx-auto tracking-wider max-w-screen-lg px-4 py-8 bg-slate-100 rounded-md">
              <h6 className="text-sm font-thin uppercase text-center py-2">
                Danke 🤝
              </h6>
              <h6 className="text-lg font-fold uppercase text-center py-2">
                Ihre Bestellung ist bestätigt
              </h6>
              <div className="w-full grid grid-cols-12 gap-4  px-2 py-2">
                <div className="col-span-12 md:col-span-6 ">
                  <div className="w-full flex flex-col space-y-2">
                    <div className="border px-2 py-2 border-slate-800 w-full">
                      <div className="w-full flex flex-col space-y-2 px-2 py-2">
                        <h6 className="text-sm uppercase font-bold  border-b border-slate-700 py-1 px-4 rounded-bl-sm">
                          Bestellinformationen
                        </h6>
                        <div className="w-full grid grid-cols-12 gap-1 py-1 px-4">
                          <div className="col-span-12 md:col-span-6">
                            <span className="text-sm font-thin">Best.-Nr#</span>
                          </div>
                          <div className="col-span-12 md:col-span-6">
                            <span className="text-sm font-bold">
                              {order.order_no}
                            </span>
                          </div>
                        </div>
                        <div className="w-full grid grid-cols-12 gap-1 py-1 px-4">
                          <div className="col-span-12 md:col-span-6">
                            <span className="text-sm font-thin">
                              Zahlungsstatus#
                            </span>
                          </div>
                          <div className="col-span-12 md:col-span-6">
                            <span className="text-sm font-bold">
                              {order.status === "paid"
                                ? "Bezahlt"
                                : "Ausstehend"}
                            </span>
                          </div>
                        </div>
                        {order.datum && order.uhrzeit ? (
                          <div className="w-full grid grid-cols-12 gap-1 py-1 px-4">
                            <div className="col-span-12 md:col-span-6">
                              <span className="text-sm font-thin">
                                {" "}
                                Datum Uhrzeit
                              </span>
                            </div>
                            <div className="col-span-12 md:col-span-6">
                              <span className="text-sm font-bold ">
                                {`${order.datum} / ${order.uhrzeit}`}
                              </span>
                            </div>
                          </div>
                        ) : null}
                        <div className="w-full grid grid-cols-12 gap-1 py-1 px-4">
                          <div className="col-span-12 md:col-span-6">
                            <span className="text-sm font-thin">
                              Lieferung or Abholung
                            </span>
                          </div>
                          <div className="col-span-12 md:col-span-6">
                            <span className="text-sm font-bold">
                              {order._order_type === "delivery"
                                ? "Lieferung"
                                : "Abholung"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    {order._order_type === "delivery" ? (
                      <React.Fragment>
                        <div className="border px-2 py-2 border-slate-800 w-full">
                          <div className="w-full flex flex-col space-y-2 px-2 py-2">
                            <h6 className="text-sm uppercase font-bold  border-b border-slate-700 py-1 px-4 rounded-bl-sm">
                              Lieferadresse
                            </h6>

                            <React.Fragment>
                              <div className="w-full grid grid-cols-12 gap-1 py-1 px-4">
                                <div className="col-span-12 md:col-span-6">
                                  <span className="text-sm font-thin">
                                    Name
                                  </span>
                                </div>
                                <div className="col-span-12 md:col-span-6">
                                  <span className="text-sm font-bold">
                                    {order.shipping_name}
                                  </span>
                                </div>
                              </div>
                              <div className="w-full grid grid-cols-12 gap-1 py-1 px-4">
                                <div className="col-span-12 md:col-span-6">
                                  <span className="text-sm font-thin">
                                    Firma Name
                                  </span>
                                </div>
                                <div className="col-span-12 md:col-span-6">
                                  <span className="text-sm font-bold">
                                    {order.shipping_firma_name
                                      ? order.shipping_firma_name
                                      : "-"}
                                  </span>
                                </div>
                              </div>
                              <div className="w-full grid grid-cols-12 gap-1 py-1 px-4">
                                <div className="col-span-12 md:col-span-6">
                                  <span className="text-sm font-thin">
                                    Klingelname/Etage
                                  </span>
                                </div>
                                <div className="col-span-12 md:col-span-6">
                                  <span className="text-sm font-bold">
                                    {` ${
                                      order.shipping_klingelname
                                        ? order.shipping_klingelname
                                        : "-"
                                    }  ${order.shipping_etage} Etg`}
                                  </span>
                                </div>
                              </div>
                              <div className="w-full grid grid-cols-12 gap-1 py-1 px-4">
                                <div className="col-span-12 md:col-span-6">
                                  <span className="text-sm font-thin">
                                    Straße/Hausnummer
                                  </span>
                                </div>
                                <div className="col-span-12 md:col-span-6">
                                  <span className="text-sm font-bold">
                                    {`${order.shipping_street}  ${order.shipping_house_number}`}
                                  </span>
                                </div>
                              </div>
                              <div className="w-full grid grid-cols-12 gap-1 py-1 px-4">
                                <div className="col-span-12 md:col-span-6">
                                  <span className="text-sm font-thin">
                                    PLZ/Ort
                                  </span>
                                </div>
                                <div className="col-span-12 md:col-span-6">
                                  <span className="text-sm font-bold">
                                    {`${order.shipping_city}`}
                                  </span>
                                </div>
                              </div>
                              <div className="w-full grid grid-cols-12 gap-1 py-1 px-4">
                                <div className="col-span-12 md:col-span-6">
                                  <span className="text-sm font-thin">
                                    Telefon
                                  </span>
                                </div>
                                <div className="col-span-12 md:col-span-6">
                                  <span className="text-sm font-bold">
                                    {`${order.shipping_phone}`}
                                  </span>
                                </div>
                              </div>
                              <div className="w-full grid grid-cols-12 gap-1 py-1 px-4">
                                <div className="col-span-12 md:col-span-6">
                                  <span className="text-sm font-thin">
                                    Email
                                  </span>
                                </div>
                                <div className="col-span-12 md:col-span-6">
                                  <span className="text-sm font-bold">
                                    {`${order.shipping_email}`}
                                  </span>
                                </div>
                              </div>
                            </React.Fragment>
                          </div>
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <div className="border px-2 py-2 border-slate-800 w-full">
                          <div className="w-full flex flex-col space-y-2 px-2 py-2">
                            <h6 className="text-sm uppercase font-bold  border-b border-slate-700 py-1 px-4 rounded-bl-sm">
                              Kontaktdafen
                            </h6>

                            <React.Fragment>
                              <div className="w-full grid grid-cols-12 gap-1 py-1 px-4">
                                <div className="col-span-12 md:col-span-6">
                                  <span className="text-sm font-thin">
                                    Name
                                  </span>
                                </div>
                                <div className="col-span-12 md:col-span-6">
                                  <span className="text-sm font-bold">
                                    {`${order.info_first_name} ${order.info_last_name}`}
                                  </span>
                                </div>
                              </div>
                              <div className="w-full grid grid-cols-12 gap-1 py-1 px-4">
                                <div className="col-span-12 md:col-span-6">
                                  <span className="text-sm font-thin">
                                    Telefon
                                  </span>
                                </div>
                                <div className="col-span-12 md:col-span-6">
                                  <span className="text-sm font-bold">
                                    {order.info_phone}
                                  </span>
                                </div>
                              </div>
                              <div className="w-full grid grid-cols-12 gap-1 py-1 px-4">
                                <div className="col-span-12 md:col-span-6">
                                  <span className="text-sm font-thin">
                                    Email
                                  </span>
                                </div>
                                <div className="col-span-12 md:col-span-6">
                                  <span className="text-sm font-bold">
                                    {order.info_email}
                                  </span>
                                </div>
                              </div>
                            </React.Fragment>
                          </div>
                        </div>
                      </React.Fragment>
                    )}

                    <div className="border px-2 py-2 border-slate-800 w-full">
                      <div className="w-full flex flex-col space-y-2 px-2 py-2">
                        <h6 className="text-sm uppercase font-bold  border-b border-slate-700 py-1 px-4 rounded-bl-sm">
                          Rechnungsadresse
                        </h6>

                        <React.Fragment>
                          <div className="w-full grid grid-cols-12 gap-1 py-1 px-4">
                            <div className="col-span-12 md:col-span-6">
                              <span className="text-sm font-thin">Name</span>
                            </div>
                            <div className="col-span-12 md:col-span-6">
                              <span className="text-sm font-bold">
                                {order.billing_name ? order.billing_name : "-"}
                              </span>
                            </div>
                          </div>
                          <div className="w-full grid grid-cols-12 gap-1 py-1 px-4">
                            <div className="col-span-12 md:col-span-6">
                              <span className="text-sm font-thin">
                                Firma_Name
                              </span>
                            </div>
                            <div className="col-span-12 md:col-span-6">
                              <span className="text-sm font-bold">
                                {order.billing_firma_name
                                  ? order.billing_firma_name
                                  : "-"}
                              </span>
                            </div>
                          </div>
                          <div className="w-full grid grid-cols-12 gap-1 py-1 px-4">
                            <div className="col-span-12 md:col-span-6">
                              <span className="text-sm font-thin">
                                Straße/Hausnummer
                              </span>
                            </div>
                            <div className="col-span-12 md:col-span-6">
                              <span className="text-sm font-bold">
                                {`${
                                  order.billing_street
                                    ? order.billing_street
                                    : ""
                                }  ${
                                  order.shipping_house_number
                                    ? order.shipping_house_number
                                    : ""
                                }`}
                              </span>
                            </div>
                          </div>
                        </React.Fragment>
                      </div>
                    </div>

                    <div className="border px-2 py-2 border-slate-800 w-full">
                      <div className="w-full flex flex-col space-y-2 px-2 py-2">
                        <h6 className="text-sm uppercase font-bold  border-b border-slate-700 py-1 px-4 rounded-bl-sm">
                          Zusätzliche Info
                        </h6>
                        <div className="w-full grid grid-cols-12 gap-1 py-1 px-4">
                          <div className="col-span-12 md:col-span-6">
                            <span className="text-sm font-thin">
                              {" "}
                              Vermerk der Bestellung
                            </span>
                          </div>
                          <div className="col-span-12 md:col-span-6">
                            <span className="text-sm font-bold">
                              {order.order_comments}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="border px-2 py-2 border-slate-800 w-full">
                      <div className="w-full flex flex-col space-y-2 px-2 py-2">
                        <h6 className="text-sm uppercase font-bold  border-b border-slate-700 py-1 px-4 rounded-bl-sm">
                          Bezahlverfahren
                        </h6>
                        <div className="w-full grid grid-cols-12 gap-1 py-1 px-4">
                          <div className="col-span-12 md:col-span-6">
                            <span className="text-sm font-thin">Methode</span>
                          </div>
                          <div className="col-span-12 md:col-span-6">
                            <img
                              src={order.payment?.logo}
                              alt={order.payment?.title}
                              className="w-auto h-10 object-contain object-center"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-span-12 md:col-span-6 ">
                  <div className="w-full flex flex-col space-y-2">
                    <div className="border px-2 py-2 border-slate-800 w-full">
                      <div className="w-full flex flex-col space-y-2 px-2 py-2">
                        <h6 className="text-sm uppercase font-bold  border-b border-slate-700 py-1 px-4 rounded-bl-sm">
                          Auftragspositionen
                        </h6>
                        {order.items.map((item, index) => (
                          <div
                            key={index}
                            className={`w-full grid grid-cols-12 ${
                              index === order.items.length - 1
                                ? "border-b border-slate-700"
                                : ""
                            }  gap-1 py-1 px-4`}
                          >
                            <div className="col-span-12 md:col-span-6">
                              <span className="text-sm font-thin">
                                {item.name} x {item.quantity}
                              </span>
                            </div>
                            <div className="col-span-12 md:col-span-6">
                              <div className="w-full flex justify-end items-center content-center">
                                <span className="text-sm  text-right font-bold">
                                  {fCurrency(
                                    Number(Number(item.price).toFixed(2))
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))}
                        <div className="w-full grid grid-cols-12 gap-1 py-1 px-4">
                          <div className="col-span-12 md:col-span-6">
                            <span className="text-sm font-thin">Summe :</span>
                          </div>
                          <div className="col-span-12 md:col-span-6">
                            <div className="w-full flex justify-end items-center content-center">
                              <span className="text-sm  text-right font-bold">
                                {fCurrency(
                                  Number(Number(order.sub_total).toFixed(2))
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                        {order._order_type === "delivery" ? (
                          <div className="w-full grid grid-cols-12 gap-1 py-1 px-4">
                            <div className="col-span-12 md:col-span-6">
                              <span className="text-sm font-thin">
                                Versandgebühr
                              </span>
                            </div>
                            <div className="col-span-12 md:col-span-6">
                              <div className="w-full flex justify-end items-center content-center">
                                <span className="text-sm  text-right font-bold">
                                  {fCurrency(Number(order.shipping_cost))}
                                </span>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {order._order_type === "collection" ? (
                          <div className="w-full grid grid-cols-12 gap-1 py-1 px-4">
                            <div className="col-span-12 md:col-span-6">
                              <span className="text-sm font-thin">
                                Abholrabatt
                              </span>
                            </div>
                            <div className="col-span-12 md:col-span-6">
                              <div className="w-full flex justify-end items-center content-center">
                                <span className="text-sm  text-right font-bold">
                                  -
                                  {fCurrency(
                                    Number(
                                      Number(order.collection_discount).toFixed(
                                        2
                                      )
                                    )
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {order.coupon_amount ? (
                          <div className="w-full grid grid-cols-12 gap-1 py-1 px-4">
                            <div className="col-span-12 md:col-span-6">
                              <span className="text-sm font-thin">
                                Coupon-Rabatt
                              </span>
                            </div>
                            <div className="col-span-12 md:col-span-6">
                              <div className="w-full flex justify-end items-center content-center">
                                <span className="text-sm  text-right font-bold">
                                  -
                                  {fCurrency(
                                    Number(
                                      Number(order.coupon_amount).toFixed(2)
                                    )
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div className="w-full grid grid-cols-12 gap-1 py-1 px-4 border-t border-slate-700">
                          <div className="col-span-12 md:col-span-6">
                            <span className="text-sm font-thin">
                              Tax (7% VAT)
                            </span>
                          </div>
                          <div className="col-span-12 md:col-span-6">
                            <div className="w-full flex flex-col justify-end ">
                              <span className="text-sm  text-right font-bold">
                                {fCurrency(
                                  Number(
                                    Number(order._tax_amount_total).toFixed(2)
                                  )
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="col-span-12">
                            <div className="w-full  text-xs font-thin text-yellow-500 py-1 flex justify-start items-center content-center">
                              <p>7% VAT</p>
                            </div>
                          </div>
                        </div>
                        <div className="w-full grid grid-cols-12 gap-1 py-1 px-4 border-t border-slate-700">
                          <div className="col-span-12 md:col-span-6">
                            <span className="text-sm font-thin">
                              Gesamtsumme
                            </span>
                          </div>
                          <div className="col-span-12 md:col-span-6">
                            <div className="w-full flex flex-col justify-end ">
                              <span className="text-sm  text-right font-bold">
                                {fCurrency(
                                  Number(Number(order.total).toFixed(2))
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="col-span-12">
                            <div className="w-full  text-xs font-thin text-yellow-500 py-1 flex justify-start items-center content-center"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <span>loading ....</span>
          </React.Fragment>
        )}
      </React.Fragment>
    </React.Fragment>
  );
};

import React, { useCallback, useEffect, useState } from "react";
import { paymentProps } from "../../../../Interfaces";
import { API, APIURLS } from "../../../../Services";
import toast from "react-hot-toast";
import { RiDeleteBinLine, RiEditLine, RiMoreLine } from "react-icons/ri";
import { AppNoData } from "../../../Atoms/AppNoData";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import { BsClockHistory } from "react-icons/bs";
import { Link } from "react-router-dom";
import moment from "moment";

export const AppPaymentListComponent: React.FC = () => {
  const [payments, setpayments] = useState<paymentProps[]>();

  const getPayments = useCallback(async () => {
    const { data } = await API.get(`${APIURLS.payments.all}`);
    const reponse: paymentProps[] = data;
    setpayments(reponse);
  }, []);

  useEffect(() => {
    getPayments();
  }, [getPayments]);

  const onDeleteHandler = async (id: number) => {
    try {
      const { data } = await API.delete(`${APIURLS.payments.delete}/${id}`);
      if (data) {
        toast.success(`shipping deleted`, {
          icon: <RiDeleteBinLine className="w-auto h-6" />,
        });
        await getPayments();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      {payments && payments.length > 0 ? (
        <React.Fragment>
          <div className="w-full px-2 py-2 flex flex-col space-y-2">
            <h4 className="font-bold text-xl text-slate-950">
              {payments.length} Payments
            </h4>
            <div className="w-full  block h-screen ">
              <ReactDataGrid
                idProperty="id"
                columns={[
                  { name: "title", header: "Name" },
                  { name: "slug", header: "Slug" },
                  {
                    name: "logo",
                    header: `Logo`,
                    render: ({value}) => (
                      <React.Fragment>
                        <img
                          className=" w-auto h-12 rounded-md border border-slate-100"
                          src={String(value)}
                          alt=""
                        />
                      </React.Fragment>
                    ),
                  },

                  {
                    name: "updated_at",
                    header: <BsClockHistory className="w-auto h-6" />,
                    minWidth: 200,
                    render: ({ value }) => (
                      <React.Fragment>
                        <div className="w-full flex flex-col space-y-0 justify-center items-center content-center">
                          <span className="text-xs font-normal">
                            {moment(new Date(value)).format("YYYY-MM-DD")}(
                            {moment(new Date(value)).fromNow()})
                          </span>
                        </div>
                      </React.Fragment>
                    ),
                    headerAlign: "center",
                  },
                  {
                    name: "id",
                    header: <RiMoreLine />,
                    headerAlign: "center",
                    minWidth: 200,
                    render: ({ value }) => (
                      <div className="w-full flex flex-row justify-center items-center content-center space-x-4">
                        <Link to={`/dashboard/payment_update/${value}`}>
                          <RiEditLine className="w-auto h-6 text-yellow-600" />
                        </Link>
                        <button
                          onClick={() => onDeleteHandler(value)}
                          type="button"
                          className="px-2 py-2"
                        >
                          <RiDeleteBinLine className="w-auto h-6 text-primary" />
                        </button>
                      </div>
                    ),
                  },
                ]}
                showEmptyRows={false}
                dataSource={payments}
                className="w-full overflow-y-auto h-5/6"
                pagination={true}
                filterable
                checkboxColumn={true}
              />
            </div>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <AppNoData message="No payments in Database." />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

import React, { useCallback, useEffect, useState } from "react";
import { orderProps } from "../../../../Interfaces";
import { API, APIURLS } from "../../../../Services";
import { AxiosResponse } from "axios";
import { AppNoData } from "../../../Atoms/AppNoData";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import { BsClockHistory } from "react-icons/bs";
import { Link } from "react-router-dom";
import moment from "moment";
import { RiEye2Line, RiMoreLine } from "react-icons/ri";
import { useAuthContext } from "../../../../ContextProviders/AuthProvider";
import { fCurrency } from "../../../../Helpers";
import { sortBy } from "lodash";
export const AppOrderListComponent: React.FC = () => {
  const [orders, setorders] = useState<orderProps[]>([]);
  const { accessToken } = useAuthContext();
  const syncOrders = useCallback(async () => {
    API.get(`${APIURLS.orders.all}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
      .then((res: AxiosResponse) => {
        if (res.data) {
          setorders(sortBy(res.data,'order_number'));
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, [accessToken]);

  useEffect(() => {
    syncOrders();
  }, [syncOrders]);

  return (
    <React.Fragment>
      {orders && orders.length > 0 ? (
        <React.Fragment>
          <div className="w-full px-2 py-2 flex flex-col space-y-2">
            <h4 className="font-bold text-xl text-slate-950">
              {orders.length} Orders
            </h4>
          </div>
          <div className="w-full  block h-screen ">
            <ReactDataGrid
              idProperty="id"
              columns={[
                { name: "order_no", header: "order_no" },
                { name: "status", header: "status" },
                { name: "_order_type", header: "_order_type" },
                { name: "_order_schedule", header: "_order_schedule" },
                {
                  name: "total",
                  textAlign: "end",
                  header: "total(€ )",
                  render: ({ value }) => (
                    <React.Fragment>{fCurrency(value)}</React.Fragment>
                  ),
                },
                {
                  name: "payment",
                  header: "Payment",
                  render: ({ value }) => (
                    <React.Fragment>
                      {value && value.title ? value.title : ""}
                    </React.Fragment>
                  ),
                },

                {
                  name: "updated_at",
                  header: <BsClockHistory className="w-auto h-6" />,
                  minWidth: 200,
                  render: ({ value }) => (
                    <React.Fragment>
                      <div className="w-full flex flex-col space-y-0 justify-center items-center content-center">
                        <span className="text-xs font-normal">
                          {moment(new Date(value)).format("YYYY-MM-DD HH:mm A")}
                        </span>
                      </div>
                    </React.Fragment>
                  ),
                  headerAlign: "center",
                },
                {
                  name: "id",
                  header: <RiMoreLine />,
                  headerAlign: "center",
                  minWidth: 200,
                  render: ({ value }) => (
                    <div className="w-full flex flex-row justify-center items-center content-center space-x-4">
                      <Link to={`/dashboard/order_view/${value}`}>
                        <RiEye2Line className="w-auto h-6 text-yellow-600" />
                      </Link>
                    </div>
                  ),
                },
              ]}
              showEmptyRows={false}
              dataSource={orders}
              className="w-full overflow-y-auto h-5/6"
              pagination={true}
              filterable
              checkboxColumn={true}
            />
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <AppNoData message="No Orders in Database." />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

import React from "react";

interface props {
  children: React.ReactNode;
}
export const AppAuthWrapper: React.FC<props> = ({ children }) => {
  return (
    <React.Fragment>
      <div className="w-full auth_wrapper_ flex justify-center items-center content-center min-h-screen">
        <div
          data-aos="fade-down"
          className="w-full max-w-md  transform overflow-hidden  bg-black relative  text-left align-middle shadow-xl transition-all"
        >
          
          <div className=" px-2 py-4 w-full flex flex-col space-y-3">
            {children}
          </div>
         
        </div>
      </div>
    </React.Fragment>
  );
};

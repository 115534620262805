import React, { useCallback, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { AppLoginScreen } from "./Login";
import { useAuthContext } from "../ContextProviders/AuthProvider";
import { AppNotFoundScreen } from "./Notfound";
import { API, APIURLS } from "../Services";
import { DashboardRoutes } from "./Dashboard/Root";
import { userProps } from "../Interfaces";
import { AppUnAuthenticatedScreen } from "./Unauthenticated";
import { AppLogoutScreen } from "./Logout";
import AOS from "aos";
import "aos/dist/aos.css";
import { AppPasswordUpdateScreen } from "./reset-password";
import { AppGetResetLinkScreen } from "./get-reset";
export const AppMainRouter: React.FC = () => {
  AOS.init();
  const { setUser, setIsAuth, setIsNotAdmin, accessToken } = useAuthContext();

  const syscAuthCallBack = useCallback(async () => {
    if (accessToken) {
      const { data, status } = await API.get(`${APIURLS.auth.get_profile}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      if (status === 200 && data) {
        const user: userProps = data;
        if (user.role === "admin") {
          setIsAuth(true);
          setUser(data);
          setIsNotAdmin(false);
        } else {
          setIsAuth(true);
          setUser(undefined);
          setIsNotAdmin(true);
        }
      }
    } else {
      setIsAuth(false);
      setUser(undefined);
      setIsNotAdmin(false);
    }
  }, [setUser, setIsAuth, setIsNotAdmin,accessToken]);

  useEffect(() => {
    syscAuthCallBack();
  }, [syscAuthCallBack]);

  return (
    <React.Fragment>
      <Router>
        <Routes>
          <Route path="/" element={<AppLoginScreen />} />
          <Route
            path="/reset-password/:reset_token"
            element={<AppPasswordUpdateScreen />}
          />
          <Route path="/get-reset-link" element={<AppGetResetLinkScreen />} />
          <Route path="dashboard/*" element={<DashboardRoutes />} />
          <Route path="*" element={<AppNotFoundScreen />} />
          <Route path="/logout" element={<AppLogoutScreen />} />
          <Route path="404" element={<Navigate replace to={`/404`} />} />
          <Route path="405" element={<AppUnAuthenticatedScreen />} />
        </Routes>
      </Router>
    </React.Fragment>
  );
};

import React from "react";
import { AiFillDatabase } from "react-icons/ai";
interface props {
  message?: string;
}
export const AppNoData: React.FC<props> = ({ message }) => {
  return (
    <React.Fragment>
      <div className="flex flex-col justify-center items-center content-center space-y-4 py-8 w-full max-w-screen-md mx-auto">
        <AiFillDatabase className="w-auto h-16 text-yellow-600" />
        <p>{message ? message : "No Data in database."}</p>
      </div>
    </React.Fragment>
  );
};

import React from "react";
import { AppCouponListComponent } from "../../../Components/Organisms/Dashbaord/Coupons/List";

export const AppCouponListScreen: React.FC = () => {
  return (
    <React.Fragment>
      <AppCouponListComponent />
    </React.Fragment>
  );
};

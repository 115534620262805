import React from "react";
import "react-modern-drawer/dist/index.css";
import Drawer from "react-modern-drawer";
import { useDashboardContext } from "../../ContextProviders/DashbaordProvider";
import { AppConfig } from "../../Config";
import { Link } from "react-router-dom";
import {
  CiBoxList,
  CiCirclePlus,
  CiFolderOn,
  CiSquarePlus,
} from "react-icons/ci";
import { FiUsers } from "react-icons/fi";
import { LiaShippingFastSolid, LiaLuggageCartSolid } from "react-icons/lia";
import {
  AiOutlineDashboard,
  AiOutlineNotification,
  AiOutlineSetting,
  AiOutlineTranslation,
} from "react-icons/ai";
import { MdCategory, MdOutlinePayments } from "react-icons/md";
import { SiProducthunt } from "react-icons/si";
import { RiCouponLine } from "react-icons/ri";
export const AppSideBar: React.FC = () => {
  const { isAside, setIsAside } = useDashboardContext();
  return (
    <React.Fragment>
      <div className="p-4 hidden lg:flex flex-col w-64 bg-black text-white ">
        <img
          src={AppConfig.boxHeaderImg}
          alt="Logo"
          className="w-auto mx-auto"
        />
        <nav>
          <ul className="text-sm  tracking-wider  ">
            <li className="mb-2 group">
              <Link
                to="/dashboard"
                className="flex items-center justify-between content-center  text-white group-hover:text-primary transition duration-300 py-2 px-4 rounded-md"
              >
                <div className="flex justify-start items-center content-center space-x-3">
                  <AiOutlineDashboard className="w-auto h-6" />
                  <span>Dashboard</span>
                </div>
              </Link>
            </li>
            <li className="mb-2 group ">
              <Link
                to="/dashboard/user_list"
                className="flex items-center justify-between content-center  text-white group-hover:text-primary transition duration-300 py-2 px-4 rounded-md"
              >
                <div className="flex justify-start items-center content-center space-x-3">
                  <FiUsers className="w-auto h-6" />
                  <span>Users</span>
                </div>
                <CiSquarePlus className="w-auto h-4 ml-5 text-slate-200 group-hover:text-primary" />
              </Link>
              <ul
                data-aos="fade-up"
                className="hidden ml-3 font-normal text-xs group-hover:flex flex-col"
              >
                <li className="">
                  <Link
                    to="/dashboard/user_list"
                    className="flex items-center justify-between content-center  text-white  transition duration-300 py-2 px-4 rounded-md"
                  >
                    <div className="flex justify-start items-center content-center space-x-3">
                      <CiBoxList className="w-auto h-4" />
                      <span>List</span>
                    </div>
                  </Link>
                </li>
                <li className="">
                  <Link
                    to="/dashboard/user_create"
                    className="flex items-center justify-between content-center  text-white  transition duration-300 py-2 px-4 rounded-md"
                  >
                    <div className="flex justify-start items-center content-center space-x-3">
                      <CiCirclePlus className="w-auto h-4" />
                      <span>Create</span>
                    </div>
                  </Link>
                </li>
              </ul>
            </li>
            <li className="mb-2 group ">
              <Link
                to="/dashboard/announcement_list"
                className="flex items-center justify-between content-center  text-white group-hover:text-primary transition duration-300 py-2 px-4 rounded-md"
              >
                <div className="flex justify-start items-center content-center space-x-3">
                  <AiOutlineNotification className="w-auto h-6" />
                  <span>Announcements</span>
                </div>
                <CiSquarePlus className="w-auto h-4 ml-5 text-slate-200 group-hover:text-primary" />
              </Link>
              <ul
                data-aos="fade-up"
                className="hidden ml-3 font-normal text-xs group-hover:flex flex-col"
              >
                <li className="">
                  <Link
                    to="/dashboard/announcement_list"
                    className="flex items-center justify-between content-center  text-white  transition duration-300 py-2 px-4 rounded-md"
                  >
                    <div className="flex justify-start items-center content-center space-x-3">
                      <CiBoxList className="w-auto h-4" />
                      <span>List</span>
                    </div>
                  </Link>
                </li>
                <li className="">
                  <Link
                    to="/dashboard/announcement_create"
                    className="flex items-center justify-between content-center  text-white  transition duration-300 py-2 px-4 rounded-md"
                  >
                    <div className="flex justify-start items-center content-center space-x-3">
                      <CiCirclePlus className="w-auto h-4" />
                      <span>Create</span>
                    </div>
                  </Link>
                </li>
              </ul>
            </li>
            <li className="mb-2 group ">
              <Link
                to="/dashboard/coupon_list"
                className="flex items-center justify-between content-center  text-white group-hover:text-primary transition duration-300 py-2 px-4 rounded-md"
              >
                <div className="flex justify-start items-center content-center space-x-3">
                  <RiCouponLine className="w-auto h-6" />
                  <span>Coupons</span>
                </div>
                <CiSquarePlus className="w-auto h-4 ml-5 text-slate-200 group-hover:text-primary" />
              </Link>
              <ul
                data-aos="fade-up"
                className="hidden ml-3 font-normal text-xs group-hover:flex flex-col"
              >
                <li className="">
                  <Link
                    to="/dashboard/coupon_list"
                    className="flex items-center justify-between content-center  text-white  transition duration-300 py-2 px-4 rounded-md"
                  >
                    <div className="flex justify-start items-center content-center space-x-3">
                      <CiBoxList className="w-auto h-4" />
                      <span>List</span>
                    </div>
                  </Link>
                </li>
                <li className="">
                  <Link
                    to="/dashboard/coupon_create"
                    className="flex items-center justify-between content-center  text-white  transition duration-300 py-2 px-4 rounded-md"
                  >
                    <div className="flex justify-start items-center content-center space-x-3">
                      <CiCirclePlus className="w-auto h-4" />
                      <span>Create</span>
                    </div>
                  </Link>
                </li>
              </ul>
            </li>
            <li className="mb-2 group">
              <Link
                to="/dashboard/shipping_list"
                className="flex items-center justify-between content-center  text-white group-hover:text-primary transition duration-300 py-2 px-4 rounded-md"
              >
                <div className="flex justify-start items-center content-center space-x-3">
                  <LiaShippingFastSolid className="w-auto h-6" />
                  <span>Shipping</span>
                </div>
                <CiSquarePlus className="w-auto h-4 ml-5 text-slate-200 group-hover:text-primary" />
              </Link>
              <ul
                data-aos="fade-up"
                className="hidden ml-3 font-normal text-xs group-hover:flex flex-col"
              >
                <li className="">
                  <Link
                    to="/dashboard/shipping_list"
                    className="flex items-center justify-between content-center  text-white  transition duration-300 py-2 px-4 rounded-md"
                  >
                    <div className="flex justify-start items-center content-center space-x-3">
                      <CiBoxList className="w-auto h-4" />
                      <span>List</span>
                    </div>
                  </Link>
                </li>
                <li className="">
                  <Link
                    to="/dashboard/shipping_create"
                    className="flex items-center justify-between content-center  text-white  transition duration-300 py-2 px-4 rounded-md"
                  >
                    <div className="flex justify-start items-center content-center space-x-3">
                      <CiCirclePlus className="w-auto h-4" />
                      <span>Create</span>
                    </div>
                  </Link>
                </li>
              </ul>
            </li>
            <li className="mb-2 group">
              <Link
                to="/dashboard/category_list"
                className="flex items-center justify-between content-center  text-white group-hover:text-primary transition duration-300 py-2 px-4 rounded-md"
              >
                <div className="flex justify-start items-center content-center space-x-3">
                  <MdCategory className="w-auto h-6" />
                  <span>Categories</span>
                </div>
                <CiSquarePlus className="w-auto h-4 ml-5 text-slate-200 group-hover:text-primary" />
              </Link>
              <ul
                data-aos="fade-up"
                className="hidden ml-3 font-normal text-xs group-hover:flex flex-col"
              >
                <li className="">
                  <Link
                    to="/dashboard/category_list"
                    className="flex items-center justify-between content-center  text-white  transition duration-300 py-2 px-4 rounded-md"
                  >
                    <div className="flex justify-start items-center content-center space-x-3">
                      <CiBoxList className="w-auto h-4" />
                      <span>List</span>
                    </div>
                  </Link>
                </li>
                <li className="">
                  <Link
                    to="/dashboard/category_create"
                    className="flex items-center justify-between content-center  text-white  transition duration-300 py-2 px-4 rounded-md"
                  >
                    <div className="flex justify-start items-center content-center space-x-3">
                      <CiCirclePlus className="w-auto h-4" />
                      <span>Create</span>
                    </div>
                  </Link>
                </li>
              </ul>
            </li>

            <li className="mb-2 group">
              <Link
                to="/dashboard/product_list"
                className="flex items-center justify-between content-center  text-white group-hover:text-primary transition duration-300 py-2 px-4 rounded-md"
              >
                <div className="flex justify-start items-center content-center space-x-3">
                  <SiProducthunt className="w-auto h-6" />
                  <span>Products</span>
                </div>
                <CiSquarePlus className="w-auto h-4 ml-5 text-slate-200 group-hover:text-primary" />
              </Link>
              <ul
                data-aos="fade-up"
                className="hidden ml-3 font-normal text-xs group-hover:flex flex-col"
              >
                <li className="">
                  <Link
                    to="/dashboard/product_list"
                    className="flex items-center justify-between content-center  text-white  transition duration-300 py-2 px-4 rounded-md"
                  >
                    <div className="flex justify-start items-center content-center space-x-3">
                      <CiBoxList className="w-auto h-4" />
                      <span>List</span>
                    </div>
                  </Link>
                </li>
                <li className="">
                  <Link
                    to="/dashboard/product_create"
                    className="flex items-center justify-between content-center  text-white  transition duration-300 py-2 px-4 rounded-md"
                  >
                    <div className="flex justify-start items-center content-center space-x-3">
                      <CiCirclePlus className="w-auto h-4" />
                      <span>Create</span>
                    </div>
                  </Link>
                </li>
              </ul>
            </li>
            <li className="mb-2 group">
              <Link
                to="/dashboard/order_list"
                className="flex items-center justify-between content-center  text-white group-hover:text-primary transition duration-300 py-2 px-4 rounded-md"
              >
                <div className="flex justify-start items-center content-center space-x-3">
                  <LiaLuggageCartSolid className="w-auto h-6" />
                  <span>Orders</span>
                </div>
              </Link>
            </li>
            <li className="mb-2 group">
              <Link
                to="/dashboard/setting_list"
                className="flex items-center justify-between content-center  text-white group-hover:text-primary transition duration-300 py-2 px-4 rounded-md"
              >
                <div className="flex justify-start items-center content-center space-x-3">
                  <AiOutlineSetting className="w-auto h-6" />
                  <span>Settings</span>
                </div>
                <CiSquarePlus className="w-auto h-4 ml-5 text-slate-200 group-hover:text-primary" />
              </Link>
              <ul
                data-aos="fade-up"
                className="hidden ml-3 font-normal text-xs group-hover:flex flex-col"
              >
                <li className="">
                  <Link
                    to="/dashboard/setting_list"
                    className="flex items-center justify-between content-center  text-white  transition duration-300 py-2 px-4 rounded-md"
                  >
                    <div className="flex justify-start items-center content-center space-x-3">
                      <CiBoxList className="w-auto h-4" />
                      <span>List</span>
                    </div>
                  </Link>
                </li>
                <li className="">
                  <Link
                    to="/dashboard/setting_create"
                    className="flex items-center justify-between content-center  text-white  transition duration-300 py-2 px-4 rounded-md"
                  >
                    <div className="flex justify-start items-center content-center space-x-3">
                      <CiCirclePlus className="w-auto h-4" />
                      <span>Create</span>
                    </div>
                  </Link>
                </li>
              </ul>
            </li>

            <li className="mb-2 group">
              <Link
                to="/dashboard/language_list"
                className="flex items-center justify-between content-center  text-white group-hover:text-primary transition duration-300 py-2 px-4 rounded-md"
              >
                <div className="flex justify-start items-center content-center space-x-3">
                  <AiOutlineTranslation className="w-auto h-6" />
                  <span>Translate</span>
                </div>
                <CiSquarePlus className="w-auto h-4 ml-5 text-slate-200 group-hover:text-primary" />
              </Link>
              <ul
                data-aos="fade-up"
                className="hidden ml-3 font-normal text-xs group-hover:flex flex-col"
              >
                <li className="">
                  <Link
                    to="/dashboard/language_list"
                    className="flex items-center justify-between content-center  text-white  transition duration-300 py-2 px-4 rounded-md"
                  >
                    <div className="flex justify-start items-center content-center space-x-3">
                      <CiBoxList className="w-auto h-4" />
                      <span>List</span>
                    </div>
                  </Link>
                </li>
                <li className="">
                  <Link
                    to="/dashboard/language_create"
                    className="flex items-center justify-between content-center  text-white  transition duration-300 py-2 px-4 rounded-md"
                  >
                    <div className="flex justify-start items-center content-center space-x-3">
                      <CiCirclePlus className="w-auto h-4" />
                      <span>Create</span>
                    </div>
                  </Link>
                </li>
              </ul>
            </li>

            <li className="mb-2 group">
              <Link
                to="/dashboard/payment_list"
                className="flex items-center justify-between content-center  text-white group-hover:text-primary transition duration-300 py-2 px-4 rounded-md"
              >
                <div className="flex justify-start items-center content-center space-x-3">
                  <MdOutlinePayments className="w-auto h-6" />
                  <span>Payments</span>
                </div>
                <CiSquarePlus className="w-auto h-4 ml-5 text-slate-200 group-hover:text-primary" />
              </Link>
              <ul
                data-aos="fade-up"
                className="hidden ml-3 font-normal text-xs group-hover:flex flex-col"
              >
                <li className="">
                  <Link
                    to="/dashboard/payment_list"
                    className="flex items-center justify-between content-center  text-white  transition duration-300 py-2 px-4 rounded-md"
                  >
                    <div className="flex justify-start items-center content-center space-x-3">
                      <CiBoxList className="w-auto h-4" />
                      <span>List</span>
                    </div>
                  </Link>
                </li>
                <li className="">
                  <Link
                    to="/dashboard/payment_create"
                    className="flex items-center justify-between content-center  text-white  transition duration-300 py-2 px-4 rounded-md"
                  >
                    <div className="flex justify-start items-center content-center space-x-3">
                      <CiCirclePlus className="w-auto h-4" />
                      <span>Create</span>
                    </div>
                  </Link>
                </li>
              </ul>
            </li>

            <li className="mb-2 group">
              <Link
                to="/dashboard/upload_list"
                className="flex items-center justify-between content-center  text-white group-hover:text-primary transition duration-300 py-2 px-4 rounded-md"
              >
                <div className="flex justify-start items-center content-center space-x-3">
                  <CiFolderOn className="w-auto h-6" />
                  <span>File Uploads</span>
                </div>
                <CiSquarePlus className="w-auto h-4 ml-5 text-slate-200 group-hover:text-primary" />
              </Link>
              <ul
                data-aos="fade-up"
                className="hidden ml-3 font-normal text-xs group-hover:flex flex-col"
              >
                <li className="">
                  <Link
                    to="/dashboard/upload_list"
                    className="flex items-center justify-between content-center  text-white  transition duration-300 py-2 px-4 rounded-md"
                  >
                    <div className="flex justify-start items-center content-center space-x-3">
                      <CiBoxList className="w-auto h-4" />
                      <span>List</span>
                    </div>
                  </Link>
                </li>
                <li className="">
                  <Link
                    to="/dashboard/upload_create"
                    className="flex items-center justify-between content-center  text-white  transition duration-300 py-2 px-4 rounded-md"
                  >
                    <div className="flex justify-start items-center content-center space-x-3">
                      <CiCirclePlus className="w-auto h-4" />
                      <span>Upload</span>
                    </div>
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>

      <Drawer
        className="w-full max-w-screen-md min-h-screen overflow-y-auto"
        open={isAside}
        direction="left"
        size={256}
        onClose={() => setIsAside(!isAside)}
      >
        <div className="w-full relative min-h-screen bg-black  flex flex-col ">
          <img src={AppConfig.boxHeaderImg} className="w-auto mx-auto" alt="" />
          <nav>
            <ul className="text-sm  tracking-wider  ">
              <li className="mb-2 group">
                <Link
                  to="/dashboard"
                  className="flex items-center justify-between content-center  text-white group-hover:text-primary transition duration-300 py-2 px-4 rounded-md"
                >
                  <div className="flex justify-start items-center content-center space-x-3">
                    <AiOutlineDashboard className="w-auto h-6" />
                    <span>Dashboard</span>
                  </div>
                </Link>
              </li>
              <li className="mb-2 group ">
                <Link
                  to="/dashboard/user_list"
                  className="flex items-center justify-between content-center  text-white group-hover:text-primary transition duration-300 py-2 px-4 rounded-md"
                >
                  <div className="flex justify-start items-center content-center space-x-3">
                    <FiUsers className="w-auto h-6" />
                    <span>Users</span>
                  </div>
                  <CiSquarePlus className="w-auto h-4 ml-5 text-slate-200 group-hover:text-primary" />
                </Link>
                <ul
                  data-aos="fade-up"
                  className="hidden ml-3 font-normal text-xs group-hover:flex flex-col"
                >
                  <li className="">
                    <Link
                      to="/dashboard/user_list"
                      className="flex items-center justify-between content-center  text-white  transition duration-300 py-2 px-4 rounded-md"
                    >
                      <div className="flex justify-start items-center content-center space-x-3">
                        <CiBoxList className="w-auto h-4" />
                        <span>List</span>
                      </div>
                    </Link>
                  </li>
                  <li className="">
                    <Link
                      to="/dashboard/user_create"
                      className="flex items-center justify-between content-center  text-white  transition duration-300 py-2 px-4 rounded-md"
                    >
                      <div className="flex justify-start items-center content-center space-x-3">
                        <CiCirclePlus className="w-auto h-4" />
                        <span>Create</span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="mb-2 group ">
              <Link
                to="/dashboard/announcement_list"
                className="flex items-center justify-between content-center  text-white group-hover:text-primary transition duration-300 py-2 px-4 rounded-md"
              >
                <div className="flex justify-start items-center content-center space-x-3">
                  <AiOutlineNotification className="w-auto h-6" />
                  <span>Announcements</span>
                </div>
                <CiSquarePlus className="w-auto h-4 ml-5 text-slate-200 group-hover:text-primary" />
              </Link>
              <ul
                data-aos="fade-up"
                className="hidden ml-3 font-normal text-xs group-hover:flex flex-col"
              >
                <li className="">
                  <Link
                    to="/dashboard/announcement_list"
                    className="flex items-center justify-between content-center  text-white  transition duration-300 py-2 px-4 rounded-md"
                  >
                    <div className="flex justify-start items-center content-center space-x-3">
                      <CiBoxList className="w-auto h-4" />
                      <span>List</span>
                    </div>
                  </Link>
                </li>
                <li className="">
                  <Link
                    to="/dashboard/announcement_create"
                    className="flex items-center justify-between content-center  text-white  transition duration-300 py-2 px-4 rounded-md"
                  >
                    <div className="flex justify-start items-center content-center space-x-3">
                      <CiCirclePlus className="w-auto h-4" />
                      <span>Create</span>
                    </div>
                  </Link>
                </li>
              </ul>
            </li>
            <li className="mb-2 group ">
              <Link
                to="/dashboard/coupon_list"
                className="flex items-center justify-between content-center  text-white group-hover:text-primary transition duration-300 py-2 px-4 rounded-md"
              >
                <div className="flex justify-start items-center content-center space-x-3">
                  <RiCouponLine className="w-auto h-6" />
                  <span>Coupons</span>
                </div>
                <CiSquarePlus className="w-auto h-4 ml-5 text-slate-200 group-hover:text-primary" />
              </Link>
              <ul
                data-aos="fade-up"
                className="hidden ml-3 font-normal text-xs group-hover:flex flex-col"
              >
                <li className="">
                  <Link
                    to="/dashboard/coupon_list"
                    className="flex items-center justify-between content-center  text-white  transition duration-300 py-2 px-4 rounded-md"
                  >
                    <div className="flex justify-start items-center content-center space-x-3">
                      <CiBoxList className="w-auto h-4" />
                      <span>List</span>
                    </div>
                  </Link>
                </li>
                <li className="">
                  <Link
                    to="/dashboard/coupon_create"
                    className="flex items-center justify-between content-center  text-white  transition duration-300 py-2 px-4 rounded-md"
                  >
                    <div className="flex justify-start items-center content-center space-x-3">
                      <CiCirclePlus className="w-auto h-4" />
                      <span>Create</span>
                    </div>
                  </Link>
                </li>
              </ul>
            </li>
              <li className="mb-2 group">
                <Link
                  to="/dashboard/shipping_list"
                  className="flex items-center justify-between content-center  text-white group-hover:text-primary transition duration-300 py-2 px-4 rounded-md"
                >
                  <div className="flex justify-start items-center content-center space-x-3">
                    <LiaShippingFastSolid className="w-auto h-6" />
                    <span>Shipping</span>
                  </div>
                  <CiSquarePlus className="w-auto h-4 ml-5 text-slate-200 group-hover:text-primary" />
                </Link>
                <ul
                  data-aos="fade-up"
                  className="hidden ml-3 font-normal text-xs group-hover:flex flex-col"
                >
                  <li className="">
                    <Link
                      to="/dashboard/shipping_list"
                      className="flex items-center justify-between content-center  text-white  transition duration-300 py-2 px-4 rounded-md"
                    >
                      <div className="flex justify-start items-center content-center space-x-3">
                        <CiBoxList className="w-auto h-4" />
                        <span>List</span>
                      </div>
                    </Link>
                  </li>
                  <li className="">
                    <Link
                      to="/dashboard/shipping_create"
                      className="flex items-center justify-between content-center  text-white  transition duration-300 py-2 px-4 rounded-md"
                    >
                      <div className="flex justify-start items-center content-center space-x-3">
                        <CiCirclePlus className="w-auto h-4" />
                        <span>Create</span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="mb-2 group">
                <Link
                  to="/dashboard/category_list"
                  className="flex items-center justify-between content-center  text-white group-hover:text-primary transition duration-300 py-2 px-4 rounded-md"
                >
                  <div className="flex justify-start items-center content-center space-x-3">
                    <MdCategory className="w-auto h-6" />
                    <span>Categories</span>
                  </div>
                  <CiSquarePlus className="w-auto h-4 ml-5 text-slate-200 group-hover:text-primary" />
                </Link>
                <ul
                  data-aos="fade-up"
                  className="hidden ml-3 font-normal text-xs group-hover:flex flex-col"
                >
                  <li className="">
                    <Link
                      to="/dashboard/category_list"
                      className="flex items-center justify-between content-center  text-white  transition duration-300 py-2 px-4 rounded-md"
                    >
                      <div className="flex justify-start items-center content-center space-x-3">
                        <CiBoxList className="w-auto h-4" />
                        <span>List</span>
                      </div>
                    </Link>
                  </li>
                  <li className="">
                    <Link
                      to="/dashboard/category_create"
                      className="flex items-center justify-between content-center  text-white  transition duration-300 py-2 px-4 rounded-md"
                    >
                      <div className="flex justify-start items-center content-center space-x-3">
                        <CiCirclePlus className="w-auto h-4" />
                        <span>Create</span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="mb-2 group">
                <Link
                  to="/dashboard/product_list"
                  className="flex items-center justify-between content-center  text-white group-hover:text-primary transition duration-300 py-2 px-4 rounded-md"
                >
                  <div className="flex justify-start items-center content-center space-x-3">
                    <SiProducthunt className="w-auto h-6" />
                    <span>Products</span>
                  </div>
                  <CiSquarePlus className="w-auto h-4 ml-5 text-slate-200 group-hover:text-primary" />
                </Link>
                <ul
                  data-aos="fade-up"
                  className="hidden ml-3 font-normal text-xs group-hover:flex flex-col"
                >
                  <li className="">
                    <Link
                      to="/dashboard/product_list"
                      className="flex items-center justify-between content-center  text-white  transition duration-300 py-2 px-4 rounded-md"
                    >
                      <div className="flex justify-start items-center content-center space-x-3">
                        <CiBoxList className="w-auto h-4" />
                        <span>List</span>
                      </div>
                    </Link>
                  </li>
                  <li className="">
                    <Link
                      to="/dashboard/product_create"
                      className="flex items-center justify-between content-center  text-white  transition duration-300 py-2 px-4 rounded-md"
                    >
                      <div className="flex justify-start items-center content-center space-x-3">
                        <CiCirclePlus className="w-auto h-4" />
                        <span>Create</span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="mb-2 group">
                <Link
                  to="/dashboard/order_list"
                  className="flex items-center justify-between content-center  text-white group-hover:text-primary transition duration-300 py-2 px-4 rounded-md"
                >
                  <div className="flex justify-start items-center content-center space-x-3">
                    <LiaLuggageCartSolid className="w-auto h-6" />
                    <span>Orders</span>
                  </div>
                </Link>
              </li>
              <li className="mb-2 group">
                <Link
                  to="/dashboard/setting_list"
                  className="flex items-center justify-between content-center  text-white group-hover:text-primary transition duration-300 py-2 px-4 rounded-md"
                >
                  <div className="flex justify-start items-center content-center space-x-3">
                    <AiOutlineSetting className="w-auto h-6" />
                    <span>Settings</span>
                  </div>
                  <CiSquarePlus className="w-auto h-4 ml-5 text-slate-200 group-hover:text-primary" />
                </Link>
                <ul
                  data-aos="fade-up"
                  className="hidden ml-3 font-normal text-xs group-hover:flex flex-col"
                >
                  <li className="">
                    <Link
                      to="/dashboard/setting_list"
                      className="flex items-center justify-between content-center  text-white  transition duration-300 py-2 px-4 rounded-md"
                    >
                      <div className="flex justify-start items-center content-center space-x-3">
                        <CiBoxList className="w-auto h-4" />
                        <span>List</span>
                      </div>
                    </Link>
                  </li>
                  <li className="">
                    <Link
                      to="/dashboard/setting_create"
                      className="flex items-center justify-between content-center  text-white  transition duration-300 py-2 px-4 rounded-md"
                    >
                      <div className="flex justify-start items-center content-center space-x-3">
                        <CiCirclePlus className="w-auto h-4" />
                        <span>Create</span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="mb-2 group">
                <Link
                  to="/dashboard/language_list"
                  className="flex items-center justify-between content-center  text-white group-hover:text-primary transition duration-300 py-2 px-4 rounded-md"
                >
                  <div className="flex justify-start items-center content-center space-x-3">
                    <AiOutlineTranslation className="w-auto h-6" />
                    <span>Translate</span>
                  </div>
                  <CiSquarePlus className="w-auto h-4 ml-5 text-slate-200 group-hover:text-primary" />
                </Link>
                <ul
                  data-aos="fade-up"
                  className="hidden ml-3 font-normal text-xs group-hover:flex flex-col"
                >
                  <li className="">
                    <Link
                      to="/dashboard/language_list"
                      className="flex items-center justify-between content-center  text-white  transition duration-300 py-2 px-4 rounded-md"
                    >
                      <div className="flex justify-start items-center content-center space-x-3">
                        <CiBoxList className="w-auto h-4" />
                        <span>List</span>
                      </div>
                    </Link>
                  </li>
                  <li className="">
                    <Link
                      to="/dashboard/language_create"
                      className="flex items-center justify-between content-center  text-white  transition duration-300 py-2 px-4 rounded-md"
                    >
                      <div className="flex justify-start items-center content-center space-x-3">
                        <CiCirclePlus className="w-auto h-4" />
                        <span>Create</span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="mb-2 group">
                <Link
                  to="/dashboard/payment_list"
                  className="flex items-center justify-between content-center  text-white group-hover:text-primary transition duration-300 py-2 px-4 rounded-md"
                >
                  <div className="flex justify-start items-center content-center space-x-3">
                    <MdOutlinePayments className="w-auto h-6" />
                    <span>Payments</span>
                  </div>
                  <CiSquarePlus className="w-auto h-4 ml-5 text-slate-200 group-hover:text-primary" />
                </Link>
                <ul
                  data-aos="fade-up"
                  className="hidden ml-3 font-normal text-xs group-hover:flex flex-col"
                >
                  <li className="">
                    <Link
                      to="/dashboard/payment_list"
                      className="flex items-center justify-between content-center  text-white  transition duration-300 py-2 px-4 rounded-md"
                    >
                      <div className="flex justify-start items-center content-center space-x-3">
                        <CiBoxList className="w-auto h-4" />
                        <span>List</span>
                      </div>
                    </Link>
                  </li>
                  <li className="">
                    <Link
                      to="/dashboard/payment_create"
                      className="flex items-center justify-between content-center  text-white  transition duration-300 py-2 px-4 rounded-md"
                    >
                      <div className="flex justify-start items-center content-center space-x-3">
                        <CiCirclePlus className="w-auto h-4" />
                        <span>Create</span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="mb-2 group">
                <Link
                  to="/dashboard/upload_list"
                  className="flex items-center justify-between content-center  text-white group-hover:text-primary transition duration-300 py-2 px-4 rounded-md"
                >
                  <div className="flex justify-start items-center content-center space-x-3">
                    <CiFolderOn className="w-auto h-6" />
                    <span>File Uploads</span>
                  </div>
                  <CiSquarePlus className="w-auto h-4 ml-5 text-slate-200 group-hover:text-primary" />
                </Link>
                <ul
                  data-aos="fade-up"
                  className="hidden ml-3 font-normal text-xs group-hover:flex flex-col"
                >
                  <li className="">
                    <Link
                      to="/dashboard/upload_list"
                      className="flex items-center justify-between content-center  text-white  transition duration-300 py-2 px-4 rounded-md"
                    >
                      <div className="flex justify-start items-center content-center space-x-3">
                        <CiBoxList className="w-auto h-4" />
                        <span>List</span>
                      </div>
                    </Link>
                  </li>
                  <li className="">
                    <Link
                      to="/dashboard/upload_create"
                      className="flex items-center justify-between content-center  text-white  transition duration-300 py-2 px-4 rounded-md"
                    >
                      <div className="flex justify-start items-center content-center space-x-3">
                        <CiCirclePlus className="w-auto h-4" />
                        <span>Upload</span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </Drawer>
    </React.Fragment>
  );
};

import React, { useCallback, useEffect, useState } from "react";
import { categoryProps } from "../../../../Interfaces";
import { API, APIURLS } from "../../../../Services";
import toast from "react-hot-toast";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import { RiDeleteBinLine, RiEditBoxLine, RiMoreLine } from "react-icons/ri";
import { AppNoData } from "../../../Atoms/AppNoData";
import { Link } from "react-router-dom";
import {
  BsCardList,
  BsClockHistory,
  BsToggleOff,
  BsToggleOn,
  BsToggles2,
  BsViewList,
} from "react-icons/bs";
import moment from "moment";
import { AxiosError, AxiosResponse } from "axios";
import { useAuthContext } from "../../../../ContextProviders/AuthProvider";

export const AppCategoryListComponent: React.FC = () => {
  const [categories, setcategories] = useState<categoryProps[]>([]);
  const {accessToken} = useAuthContext()
  const syncList = useCallback(async () => {
    try {
      const response = await API.get(`${APIURLS.categories.all}`,{headers:{'Authorization':`Bearer ${accessToken}`}});
      const listReponse: categoryProps[] = response.data;
      setcategories(listReponse);
    } catch (error) {
      console.log(`API error ${error}`);
    }
  }, [accessToken]);
  useEffect(() => {
    syncList();
  }, [syncList]);

  const onDeleteHandler = async (id: number) => {
    try {
      const { data } = await API.delete(`${APIURLS.categories.delete}/${id}`);
      if (data) {
        toast.success(`category deleted`, {
          icon: <RiDeleteBinLine className="w-auto h-6" />,
        });
        await syncList();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onToggleclick = async (id: number) => {
    try {
      API.patch(`${APIURLS.categories.toggle}/${id}`, {})
        .then((res: AxiosResponse) => {
          if (res.data) {
            toast.success("success");
            syncList();
          }
        })
        .catch((err: AxiosError) => {
          toast.error(`${err.message}`);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      {categories && categories.length > 0 ? (
        <React.Fragment>
          <div className="w-full px-2 py-2 flex flex-col space-y-2">
            <h4 className="font-bold text-xl text-slate-950">
              {categories.length} Categories
            </h4>
          </div>
          <div className="w-full block h-screen">
            <ReactDataGrid
              columns={[
                {
                  name: "name",
                  header: "Name",
                },
                { name: "menu_order", header: "Menu Order" },
                {
                  name: "slug",
                  header: "Slug",
                },
                {
                  name: "is_list",
                  header: "view Style",
                  render: ({ value }) => (
                    <React.Fragment>
                      {Boolean(value) ? (
                        <BsCardList className="w-auto h-6 text-slate-500" />
                      ) : (
                        <BsViewList className="w-auto h-6 text-slate-500" />
                      )}
                    </React.Fragment>
                  ),
                },
                {
                  name: "products",
                  header: "No of Products",

                  render: ({ value }) => (
                    <React.Fragment>
                      <span>{value && value.length && value.length}</span>
                    </React.Fragment>
                  ),
                },
                {
                  name: "is_active",
                  header: <BsToggles2 className="w-auto h-6" />,

                  render: ({ value }) => (
                    <React.Fragment>
                     <div
                       
                       
                        className="w-full flex flex-row justify-center items-center content-center"
                      >
                        {Boolean(value) ? (
                          <BsToggleOn className="text-green-600 w-auto h-8 text-center" />
                        ) : (
                          <BsToggleOff className="text-red-600 w-auto h-8 text-center" />
                        )}
                      </div>
                    </React.Fragment>
                  ),
                },
                {
                  name: "updated_at",
                  header: <BsClockHistory className="w-auto h-6" />,
                  minWidth: 200,
                  render: ({ value }) => (
                    <React.Fragment>
                      <div className="w-full flex flex-col space-y-0 justify-center items-center content-center">
                        <span className="text-xs font-normal">
                          {moment(new Date(value)).format("YYYY-MM-DD")}(
                          {moment(new Date(value)).fromNow()})
                        </span>
                      </div>
                    </React.Fragment>
                  ),
                  headerAlign: "center",
                },

                {
                  name: "id",
                  header: <RiMoreLine />,
                  minWidth: 200,
                  render: ({ value }) => (
                    <div className="w-full flex flex-row justify-center items-center content-center space-x-4">
                      <button
                        type="button"
                        onClick={() => onToggleclick(value)}
                        className="w-full flex flex-row justify-center items-center content-center"
                      >
                        <BsToggles2 className="w-auto h-6 text-gray-700" />
                      </button>
                      <Link to={`/dashboard/category_update/${value}`}>
                        <RiEditBoxLine className="w-auto h-6 text-yellow-600" />
                      </Link>
                      <button
                        onClick={() => onDeleteHandler(value)}
                        type="button"
                        className="px-2 py-2"
                      >
                        <RiDeleteBinLine className="w-auto h-6 text-primary" />
                      </button>
                    </div>
                  ),
                },
              ]}
              checkboxColumn={true}
              idProperty="id"
              showEmptyRows={false}
              dataSource={categories}
              className="w-full overflow-y-auto h-5/6"
              pagination={true}
              filterable
            />
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <AppNoData message="No categories in database." />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

import React, { useCallback, useEffect, useState } from "react";
import { API, APIURLS } from "../../../../Services";
import { AppNoData } from "../../../Atoms/AppNoData";
import { useAuthContext } from "../../../../ContextProviders/AuthProvider";

export const AppFileListComponent: React.FC = () => {
  const [files, setfiles] = useState<string[]>([]);
  const {accessToken} = useAuthContext()
  const getFiles = useCallback(async () => {
    const { data } = await API.get(`${APIURLS.uploads.all}`,{headers:{'Authorization':`Bearer ${accessToken}`}});
    const response: string[] = data;
    setfiles(response);
  }, [accessToken]);
  useEffect(() => {
    getFiles();
  }, [getFiles]);

  return (
    <React.Fragment>
      {files && files.length > 0 ? (
        <React.Fragment>
          <div className="w-full flex flex-col space-y-2">
            <h4 className="text-lg font-bold text-slate-950">
              {files.length} files
            </h4>
            <div className="w-full max-w-screen-xl grid grid-cols-12 gap-3">
              {files.map((f, i) => (
                <div key={i} className="col-span-12 md:col-span-3">
                  <div className="w-full relative group h-auto px-2 py-2  border-slate-300 border ">
                    <img
                      src={f}
                      alt=""
                      className="w-full h-52 object-center "
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <AppNoData message="No files in server" />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

import React from "react";
import { fShortenNumber } from "../../Helpers";

interface props {
  icon: React.ReactNode;
  title: string;
  total: number;
  effect?:string;
}
export const AppDashbardCard: React.FC<props> = ({ icon, title, total,effect="fade-up" }) => {
  return (
    <React.Fragment>
      <div
       data-aos={effect}
        className="bg-gradient-to-br  from-black to-secondary p-6 rounded-md shadow-lg text-slate-50"
      >
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center">
            {icon}
            <div>
              <h2 className="text-lg font-semibold">{title}</h2>
            </div>
          </div>
          <span className="text-2xl font-bold">{fShortenNumber(total)}</span>
        </div>
      </div>
    </React.Fragment>
  );
};

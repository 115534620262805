import React from "react";
import { Link } from "react-router-dom";
import { AppConfig } from "../Config";

export const AppNotFoundScreen: React.FC = () => {
  return (
    <React.Fragment>
      <div className="flex flex-col items-center justify-center h-screen">
        <img
          src={AppConfig.notFoundImg} 
          alt="Page Not Found"
          className=" w-auto h-36 mb-8 "
        />
        <p className="text-xl mb-4">
          Oops! The page you're looking for doesn't exist.
        </p>
        <p className="text-lg mb-8">
          Let's go back to{" "}
          <Link to="/" className="text-blue-500 underline">
            the home page
          </Link>
          .
        </p>
      </div>
    </React.Fragment>
  );
};

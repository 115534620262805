import React from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { RiArrowRightSLine, RiQuestionAnswerLine } from "react-icons/ri";
import { AxiosResponse } from "axios";
import { Link } from "react-router-dom";
import { API, APIURLS } from "../Services";
interface props {
  email: string;
}
export const AppGetResetLinkScreen: React.FC = () => {
  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid },
  } = useForm<props>({
    mode: "onChange",
    defaultValues: {
      email: "",
    },
  });

  const SubmitForm = (data: props) => {
    try {
      API.post(`${APIURLS.auth.get_reset_link}`, data)
        .then((res: AxiosResponse) => {
          if (res.data && res.data.status && res.data.status === 200) {
            setError("email", {
              type: "validate",
              message:
                "We've sent an email to your inbox to reset your password.Please check your email, including the spam folder, for further steps.",
            });
          } else if (res.data && res.data.status && res.data.status === 404) {
            setError("email", {
              type: "validate",
              message: "Your email doesn't exist!",
            });
          } else {
            setError("email", {
              type: "validate",
              message: "Something wrong!",
            });
          }
        })
        .catch((error: any) => {
          toast.error(`Something wrong`, {
            icon: <RiQuestionAnswerLine className="w-auto h-6" />,
          });
        });
    } catch (error) {
      toast.error(`Something wrong`, {
        icon: <RiQuestionAnswerLine className="w-auto h-6" />,
      });
    }
  };

  return (
    <React.Fragment>
      <div className="w-full flex  flex-col text-white h-screen justify-center items-center content-center space-y-2">
        <form
          onSubmit={handleSubmit(SubmitForm)}
          className="w-full mx-auto max-w-screen-sm py-6 bg-black rounded-md shadow-md px-4"
        >
          <div className="w-full flex flex-col space-y-2">
            <h6 className="text-lg font-bold">Forgot password</h6>
            <p className="text-sm tracking-wider font-thin">
              Enter your email address below and we will send you a link to
              reset your password.
            </p>
          </div>
          <div className="w-full flex flex-col space-y-2 py-4 px-2">
            <label className="text-sm font-thin" htmlFor="email">
              Email Address
            </label>
            <Controller
              control={control}
              name="email"
               
              rules={{
                required: {
                  value: true,
                  message: "Please Don't Leave It Blank.",
                },
              }}
              render={({
                field: { onBlur, onChange, value, ref },
                formState: { errors },
              }) => (
                <React.Fragment>
                  <input
                    type="email"
                    ref={ref}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    onBlur={onBlur}
                    className="w-full bg-black  form-input border-white "
                  />
                  {errors && errors.email && errors.email.message ? (
                    <React.Fragment>
                      <span className="text-xs tracking-widest text-white font-light">
                        {errors.email.message}
                      </span>
                    </React.Fragment>
                  ) : null}
                </React.Fragment>
              )}
            />
          </div>
          <div className="flex w-full px-2 py-2 flex-col">
            <button
              disabled={!isValid}
              type="submit"
              className="w-full px-2 py-2 flex flex-row justify-center items-center content-center bg-primary hover:bg-secondary text-white"
            >
              <span className="text-sm font-bold tracking-widest flex-grow">
                Reset password
              </span>
              <RiArrowRightSLine className="w-auto h-6 " />
            </button>
          </div>
          <div className="w-full flex flex-col space-y-2">
            <p className="text-sm tracking-wider font-thin">
              If you need additional help, please contact{" "}
              <Link className="text-primary underline" to={`/kontakt`}>
                customer service
              </Link>
            </p>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

import React from "react";

import { AppNav } from "./AppNav";
import { AppSideBar } from "./AppSidebar";

interface props {
  children: React.ReactNode;
}
export const AppDashbaordWrapper: React.FC<props> = ({ children }) => {
  return (
    <React.Fragment>
      <div className="flex flex-col lg:flex-row h-screen bg-gray-100">
        <AppSideBar />
        <div className="flex-1 flex flex-col lg:overflow-hidden">
          <AppNav />
          {/* Main Content */}
          <main className="flex-1 overflow-x-hidden overflow-y-auto bg-slate-200 p-4">
            {children}
          </main>
        </div>
      </div>
    </React.Fragment>
  );
};

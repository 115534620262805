import React from "react";

import { Navigate, Route, Routes } from "react-router-dom";
import { AppDashbaordHomeScreen } from "./Home";
import { AppUserListScreen } from "./Users";
import { AppUserUpdateScreen } from "./Users/update";
import { AppUserCreateScreen } from "./Users/create";
import { useAuthContext } from "../../ContextProviders/AuthProvider";
import { AppDashbaordWrapper } from "../../Components/Modules/AppDashbaordWrapper";
import { AppDashboardContextProvider } from "../../ContextProviders/DashbaordProvider";
import { AppNotFoundScreen } from "../Notfound";
import { AppShippingCreateScreen } from "./Shipping/Create";
import { AppShippingEditScreen } from "./Shipping/Edit";
import { AppShippingListScreen } from "./Shipping/List";
import { AppCategoryListScreen } from "./Categories/List";
import { AppCategoryEditScreen } from "./Categories/Edit";
import { AppCategoryCreateScreen } from "./Categories/Create";
import { AppProductListScreen } from "./Products/List";
import { AppProductEditScreen } from "./Products/Edit";
import { AppProductCreateScreen } from "./Products/Create";
import { AppOrderListScreen } from "./Orders/List";
import { AppOrderViewScreen } from "./Orders/View";
import { AppSettingCreateScreen } from "./Settings/Create";
import { AppSettingEditScreen } from "./Settings/Edit";
import { AppSettingListScreen } from "./Settings/List";
import { AppLanguageListScreen } from "./Languages/List";
import { AppLanguageEditScreen } from "./Languages/Edit";
import { AppLanguageCreateScreen } from "./Languages/Create";
import { AppPaymentListScreen } from "./Payment/List";
import { AppPaymentCreateScreen } from "./Payment/Create";
import { AppPaymentEditScreen } from "./Payment/Edit";
import { AppFileListScreen } from "./Files/List";
import { AppFileUploadScreen } from "./Files/Upload";
import { AppAnnouncementListScreen } from "./Announcements/List";
import { AppAnnouncementEditScreen } from "./Announcements/Edit";
import { AppAnnouncementCreateScreen } from "./Announcements/Create";
import { AppCouponListScreen } from "./Coupons/List";
import { AppCouponEditScreen } from "./Coupons/Edit";
import { AppCouponCreateScreen } from "./Coupons/Create";

export const DashboardRoutes: React.FC = () => {
  const { isAuth, isNotAdmin } = useAuthContext();

  if (!isAuth) {
    return <Navigate to="/" replace />;
  } else if (isAuth && isNotAdmin) {
    return <Navigate to="/405" replace />;
  }
  return (
    <React.Fragment>
      <AppDashboardContextProvider>
        <AppDashbaordWrapper>
          <Routes>
            <Route index element={<AppDashbaordHomeScreen />} />
            {/* users */}
            <Route path="user_list" element={<AppUserListScreen />} />
            <Route path="user_update/:id" element={<AppUserUpdateScreen />} />
            <Route path="user_create" element={<AppUserCreateScreen />} />

            {/* announcement */}
            <Route
              path="announcement_list"
              element={<AppAnnouncementListScreen />}
            />
            <Route
              path="announcement_update/:id"
              element={<AppAnnouncementEditScreen />}
            />
            <Route
              path="announcement_create"
              element={<AppAnnouncementCreateScreen />}
            />

            {/* coupon */}
            <Route path="coupon_list" element={<AppCouponListScreen />} />
            <Route path="coupon_update/:id" element={<AppCouponEditScreen />} />
            <Route path="coupon_create" element={<AppCouponCreateScreen />} />
            {/* shipping */}
            <Route path="shipping_list" element={<AppShippingListScreen />} />
            <Route
              path="shipping_update/:id"
              element={<AppShippingEditScreen />}
            />
            <Route
              path="shipping_create"
              element={<AppShippingCreateScreen />}
            />

            {/* categories */}
            <Route path="category_list" element={<AppCategoryListScreen />} />
            <Route
              path="category_update/:id"
              element={<AppCategoryEditScreen />}
            />
            <Route
              path="category_create"
              element={<AppCategoryCreateScreen />}
            />

            {/* products */}
            <Route path="product_list" element={<AppProductListScreen />} />
            <Route
              path="product_update/:id"
              element={<AppProductEditScreen />}
            />
            <Route path="product_create" element={<AppProductCreateScreen />} />
            {/* orders */}
            <Route path="order_list" element={<AppOrderListScreen />} />
            <Route path="order_view/:id" element={<AppOrderViewScreen />} />
            {/* setting */}
            <Route path="setting_list" element={<AppSettingListScreen />} />
            <Route
              path="setting_update/:id"
              element={<AppSettingEditScreen />}
            />
            <Route path="setting_create" element={<AppSettingCreateScreen />} />

            {/* setting */}
            <Route path="language_list" element={<AppLanguageListScreen />} />
            <Route
              path="language_update/:id"
              element={<AppLanguageEditScreen />}
            />
            <Route
              path="language_create"
              element={<AppLanguageCreateScreen />}
            />

            {/* payment */}
            <Route path="payment_list" element={<AppPaymentListScreen />} />
            <Route
              path="payment_update/:id"
              element={<AppPaymentEditScreen />}
            />
            <Route path="payment_create" element={<AppPaymentCreateScreen />} />

            {/* file manager */}
            <Route path="upload_list" element={<AppFileListScreen />} />
            <Route path="upload_create" element={<AppFileUploadScreen />} />
            <Route path="*" element={<AppNotFoundScreen />} />
            <Route path="404" element={<Navigate replace to={`/404`} />} />
          </Routes>
        </AppDashbaordWrapper>
      </AppDashboardContextProvider>
    </React.Fragment>
  );
};

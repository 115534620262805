import { replace } from "lodash";
import moment from "moment";
import numeral from "numeral";
export const generateTimeSlots = () => {
  const timeSlots = [];
  const totalMinutes = 24 * 60 + 15; // Total minutes in a day

  for (let minutes = 0; minutes < totalMinutes; minutes += 15) {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;

    const formattedTime = `${String(hours).padStart(2, "0")}:${String(
      mins
    ).padStart(2, "0")}`;
    timeSlots.push(formattedTime);
  }

  return timeSlots;
};

export const fCurrency = (number: number) => {
  return `€${String(Number(number).toFixed(2)).replace(".", ",")}`;
};

export const fPercent = (number: number) => {
  return numeral(number / 100).format("0.0%");
};

export const fNumber = (number: number) => {
  return numeral(number).format();
};

export const fShortenNumber = (number: number) => {
  return replace(numeral(number).format("0.00a"), ".00", "");
};

export const getMomentDateTime = (time: string | undefined) => {
  return moment(
    moment().format("YYYY-MM-DD") + " " + time,
    "YYYY-MM-DD HH:mm:ss"
  );
};

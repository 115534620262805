import React from "react";
import { AppAuthContextProvider } from "./ContextProviders/AuthProvider";
import AOS from "aos";
import { AppMainRouter } from "./Routes/Index";
import "aos/dist/aos.css";
import '@inovua/reactdatagrid-community/index.css';
import  { Toaster } from 'react-hot-toast';
export const AppRoot: React.FC = () => {
  AOS.init();
  return (
    <React.Fragment>
     <React.Fragment>
          <AppAuthContextProvider>
            <AppMainRouter />
          </AppAuthContextProvider>
        </React.Fragment>
      <Toaster />
    </React.Fragment>
  );
};

import React, { useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { API, APIURLS } from "../../../../Services";
import toast from "react-hot-toast";

import { useParams } from "react-router-dom";
import { categoryProps } from "../../../../Interfaces";
import { categoryDto } from "../../../../Interfaces/form";
import { AiFillPlusSquare } from "react-icons/ai";
import { CiGrid41, CiViewList } from "react-icons/ci";
import { useAuthContext } from "../../../../ContextProviders/AuthProvider";

export const AppCategoryEditFormComponent: React.FC = () => {
  const { id } = useParams();
  const { control, handleSubmit, watch, setValue } = useForm<categoryDto>({
    mode: "onChange",
    defaultValues: {
      name: "",
      is_active: true,
      menu_order: 0,
      is_list: true,
    },
  });
  const { accessToken } = useAuthContext();
  const setDataSync = useCallback(async () => {
    const { data } = await API.get(`${APIURLS.categories.get}/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    if (data) {
      const category: categoryProps = data;
      setValue("name", category.name ? category.name : "");

      setValue(
        "menu_order",
        category.menu_order ? Number(category.menu_order) : 0
      );
      setValue("is_active", category.is_active ? true : false);
      setValue("is_list", category.is_list ? true : false);
    }
  }, [setValue, id, accessToken]);

  useEffect(() => {
    setDataSync();
  }, [setDataSync]);

  const onSubmit = (data: categoryDto) => {
    if (data) {
      API.patch(
        `${APIURLS.categories.update}/${id}`,
        {
          name: data.name ? data.name : "",
          is_list: data.is_list ? true : false,
          is_active: data.is_active ? true : false,
          menu_order: data.menu_order ? Number(data.menu_order) : 0,
        },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
        .then((res) => {
          if (res.data) {
            toast.success("New category updated.", {
              className: "text-sm font-normal",
              icon: <AiFillPlusSquare className="w-auto h-6" />,
            });
          }
        })
        .catch((err: any) => {
          console.log(err);
        })
        .finally(() => {
          console.log(`category create API done.`);
        });
    }
  };
  return (
    <React.Fragment>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full flex flex-col max-w-screen-md  space-y-2"
      >
        <div className="flex flex-col space-y-2 ">
          <label className="text-sm font-normal" htmlFor="first_name">
            Name
          </label>
          <Controller
            name="name"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Please Don't Leave It Blank.",
              },
            }}
            render={({
              field: { onBlur, onChange, value, ref },
              formState: { errors },
            }) => (
              <React.Fragment>
                <input
                  type="text"
                  ref={ref}
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  onBlur={onBlur}
                  className="w-full bg-white  form-input border-white "
                />
                {errors && errors.name && errors.name.message ? (
                  <React.Fragment>
                    <span className="text-xs text-primary font-light">
                      {errors.name.message}
                    </span>
                  </React.Fragment>
                ) : null}
              </React.Fragment>
            )}
          />
        </div>
        <div className="flex flex-col space-y-2 ">
          <label className="text-sm font-normal" htmlFor="first_name">
            Menu Order
          </label>
          <Controller
            name="menu_order"
            control={control}
            rules={{
              required: {
                value: false,
                message: "Please Don't Leave It Blank.",
              },
            }}
            render={({
              field: { onBlur, onChange, value, ref },
              formState: { errors },
            }) => (
              <React.Fragment>
                <input
                  type="number"
                  ref={ref}
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  onBlur={onBlur}
                  className="w-full bg-white  form-input border-white "
                />
                {errors && errors.menu_order && errors.menu_order.message ? (
                  <React.Fragment>
                    <span className="text-xs text-primary font-light">
                      {errors.menu_order.message}
                    </span>
                  </React.Fragment>
                ) : null}
              </React.Fragment>
            )}
          />
        </div>
        <div className="w-full flex flex-col space-y-2">
          <label className="text-sm font-normal" htmlFor="is_list">
            Product display style
          </label>
          <div className=" space-x-6 flex flex-row justify-start items-center content-center">
            <button
              type="button"
              onClick={() => setValue("is_list", true)}
              className={`px-2 py-2 rounded-md border focus:outline-none ${
                watch("is_list") ? "border-primary" : "border-white"
              } `}
            >
              <CiViewList className="w-auto h-12 text-slate-600" />
            </button>
            <button
              type="button"
              onClick={() => setValue("is_list", false)}
              className={`px-2 py-2 rounded-md border focus:outline-none ${
                !watch("is_list") ? "border-primary" : "border-white"
              } `}
            >
              <CiGrid41 className="w-auto h-12 text-slate-600" />
            </button>
          </div>
        </div>
        <div className="w-full flex flex-row space-x-2">
          <Controller
            control={control}
            name="is_active"
            render={({ field: { value, onChange } }) => (
              <React.Fragment>
                <input
                  checked={Boolean(value)}
                  onChange={(e) => onChange(e.target.checked)}
                  type="checkbox"
                  className="form-checkbox"
                />
              </React.Fragment>
            )}
          />
          <label className="text-sm font-normal" htmlFor="is_list">
            Is Active ?
          </label>
        </div>
        <div className="py-2 flex flex-row">
          <button
            type="submit"
            className="px-4 font-bold text-sm tracking-widest py-2 bg-primary text-white hover:bg-secondary"
          >
            Save
          </button>
        </div>
      </form>
    </React.Fragment>
  );
};

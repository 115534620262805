import React, { useCallback, useEffect } from "react";
import { useAuthContext } from "../ContextProviders/AuthProvider";
import { Navigate } from "react-router-dom";

export const AppLogoutScreen: React.FC = () => {
  const { setIsAuth, setUser, setIsNotAdmin } = useAuthContext();
  
  const syncLogout = useCallback(() => {
    localStorage.clear();
    setIsAuth(false);
    setIsNotAdmin(false);
    setUser(undefined);
  }, [setIsAuth, setIsNotAdmin, setUser]);

  useEffect(() => {
    syncLogout();
  }, [syncLogout]);

  return <Navigate to={`/`} />;
};

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { productProps } from "../../../../Interfaces";
import { API, APIURLS } from "../../../../Services";
import toast from "react-hot-toast";
import { RiDeleteBinLine, RiEditLine, RiMoreLine } from "react-icons/ri";
import { AppNoData } from "../../../Atoms/AppNoData";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import { BsClockHistory, BsToggle2Off, BsToggle2On } from "react-icons/bs";
import { Link } from "react-router-dom";
import moment from "moment";
import { useAuthContext } from "../../../../ContextProviders/AuthProvider";

export const AppProductListComponent: React.FC = () => {
  const [products, setproducts] = useState<productProps[]>([]);
  const {accessToken} = useAuthContext()
  const syncProducts = useCallback(async () => {
    try {
      const response = await API.get(`${APIURLS.products.all}`,{headers:{'Authorization':`Bearer ${accessToken}`}});
      const reponse: productProps[] = response.data;
      setproducts(reponse);
    } catch (error) {
      console.log(`API error ${error}`);
    }
  }, [accessToken]);
  useEffect(() => {
    syncProducts();
  }, [syncProducts]);

  const onDeleteHandler = async (id: number) => {
    try {
      const { data } = await API.delete(`${APIURLS.products.delete}/${id}`,{headers:{'Authorization':`Bearer ${accessToken}`}});
      if (data) {
        toast.success(`product deleted`, {
          icon: <RiDeleteBinLine className="w-auto h-6" />,
        });
        await syncProducts();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [searchText, setsearchText] = useState("");
  const filterProducts = useMemo(() => {
    if (products) {
      return products.filter((p) => p.title.toLowerCase().includes(searchText.toLowerCase()));
    } else {
      return [];
    }
  }, [products, searchText]);
  return (
    <React.Fragment>
      {products && products.length > 0 ? (
        <React.Fragment>
          <div className="w-full px-2 py-2 flex flex-col space-y-2">
            <h4 className="font-bold text-xl text-slate-950">
              {products.length} Products
            </h4>
            <input
              type="text"
              value={searchText}
              onChange={(e) => setsearchText(e.target.value)}
              className="form-input w-full max-w-screen-sm"
              placeholder="search products..."
            />

            <div className="w-full  block h-screen ">
              <ReactDataGrid
                columns={[
                  { name: "title", header: "Title" },
                 
                  {
                    name: "img",
                    header: "Image",
                    render: ({ value }) => (
                      <React.Fragment>
                        <img
                          src={value}
                          alt=""
                          className="w-8 h-8 object-cover object-center"
                        />
                      </React.Fragment>
                    ),
                  },
                  { name: "slug", header: "Slug" },
                  { name: "short_description", header: "Description" },
                  { name: "_regular_price", header: "_regular_price(€ )" },
                  { name: "_sale_price", header: "_sale_price(€ )" },
                  {
                    name: "is_active",
                    header: "Published?",
                    headerAlign: "center",
                    render: ({ value }) => (
                      <React.Fragment>
                        <div className="w-full flex flex-row justify-center items-center content-center">
                          {Boolean(value) ? (
                            <BsToggle2On className="text-green-600 w-auto h-6 text-center" />
                          ) : (
                            <BsToggle2Off className="text-red-600 w-auto h-6 text-center" />
                          )}
                        </div>
                      </React.Fragment>
                    ),
                  },
                  {
                    name: "updated_at",
                    header: <BsClockHistory className="w-auto h-6" />,
                    minWidth: 200,
                    render: ({ value }) => (
                      <React.Fragment>
                        <div className="w-full flex flex-col space-y-0 justify-center items-center content-center">
                          <span className="text-xs font-normal">
                            {moment(new Date(value)).format("YYYY-MM-DD")}(
                            {moment(new Date(value)).fromNow()})
                          </span>
                        </div>
                      </React.Fragment>
                    ),
                    headerAlign: "center",
                  },
                  {
                    name: "id",
                    header: <RiMoreLine />,
                    headerAlign: "center",
                    minWidth: 200,
                    render: ({ value }) => (
                      <div className="w-full flex flex-row justify-center items-center content-center space-x-4">
                        <Link to={`/dashboard/product_update/${value}`}>
                          <RiEditLine className="w-auto h-6 text-yellow-600" />
                        </Link>
                        <button
                          onClick={() => onDeleteHandler(value)}
                          type="button"
                          className="px-2 py-2"
                        >
                          <RiDeleteBinLine className="w-auto h-6 text-primary" />
                        </button>
                      </div>
                    ),
                  },
                ]}
                idProperty="id"
                showEmptyRows={false}
                dataSource={filterProducts}
                className="w-full overflow-y-auto h-5/6"
                pagination={true}
                filterable
                checkboxColumn={true}
              />
            </div>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <AppNoData message="No products in database." />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

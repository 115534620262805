import React from "react";
import { Controller, useForm } from "react-hook-form";
import { API, APIURLS } from "../../../../Services";
import toast from "react-hot-toast";
import { AiFillPlusSquare } from "react-icons/ai";
import { useAuthContext } from "../../../../ContextProviders/AuthProvider";

export const AppLanguageCreateFormComponent: React.FC = () => {
  
  const {
    control,
    handleSubmit,
   
   
  } = useForm({
    mode: "onChange",

    defaultValues: {
      key: "",
      en: "",
      de: "",
    },
  });

  const {accessToken} = useAuthContext()

  const onSubmit = (data: any) => {
    if (data) {
      API.post(`${APIURLS.languages.create}`, data,{headers:{'Authorization':`Bearer ${accessToken}`}})
        .then((res) => {
          if (res.data) {
            toast.success("New Tanslation created.", {
              className: "text-sm font-normal",
              icon: <AiFillPlusSquare className="w-auto h-6" />,
            });
          }
        })
        .catch((err: any) => {
          console.log(err);
        })
        .finally(() => {
          console.log(`tanslation create API done.`);
        });
    }
  };
  return (
    <React.Fragment>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full flex flex-col max-w-screen-md  space-y-2"
      >
        <div className="flex flex-col space-y-2 ">
          <label className="text-sm font-normal" htmlFor="key">
            Name
          </label>
          <Controller
            name="key"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Please Don't Leave It Blank.",
              },
            }}
            render={({
              field: { onBlur, onChange, value, ref },
              formState: { errors },
            }) => (
              <React.Fragment>
                <input
                  type="text"
                  ref={ref}
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  onBlur={onBlur}
                  className="w-full bg-white  form-input border-white "
                />
                {errors && errors.key && errors.key.message ? (
                  <React.Fragment>
                    <span className="text-xs text-primary font-light">
                      {errors.key.message}
                    </span>
                  </React.Fragment>
                ) : null}
              </React.Fragment>
            )}
          />
        </div>
        <div className="flex flex-col space-y-2 ">
          <label className="text-sm font-normal" htmlFor="en">
            English
          </label>
          <Controller
            name="en"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Please Don't Leave It Blank.",
              },
            }}
            render={({
              field: { onBlur, onChange, value, ref },
              formState: { errors },
            }) => (
              <React.Fragment>
                <textarea
                  rows={4}
                  ref={ref}
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  onBlur={onBlur}
                  className="w-full bg-white form-textarea border-white "
                />
                {errors && errors.en && errors.en.message ? (
                  <React.Fragment>
                    <span className="text-xs text-primary font-light">
                      {errors.en.message}
                    </span>
                  </React.Fragment>
                ) : null}
              </React.Fragment>
            )}
          />
        </div>
        <div className="flex flex-col space-y-2 ">
          <label className="text-sm font-normal" htmlFor="de">
          Deutschland
          </label>
          <Controller
            name="de"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Please Don't Leave It Blank.",
              },
            }}
            render={({
              field: { onBlur, onChange, value, ref },
              formState: { errors },
            }) => (
              <React.Fragment>
                <textarea
                  rows={4}
                  ref={ref}
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  onBlur={onBlur}
                  className="w-full bg-white form-textarea border-white "
                />
                {errors && errors.de && errors.de.message ? (
                  <React.Fragment>
                    <span className="text-xs text-primary font-light">
                      {errors.de.message}
                    </span>
                  </React.Fragment>
                ) : null}
              </React.Fragment>
            )}
          />
        </div>
        <div className="py-2 flex flex-row">
          <button
          
            type="submit"
            className="px-4 font-bold text-sm tracking-widest py-2 bg-primary text-white hover:bg-secondary"
          >
            Save
          </button>
        </div>
      </form>
    </React.Fragment>
  );
};

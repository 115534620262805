import React from 'react';
import { AppOrderListComponent } from '../../../Components/Organisms/Dashbaord/Order/AppOrderList';

export const AppOrderListScreen:React.FC =()=>{
    
    return(
        <React.Fragment>
            <AppOrderListComponent />
        </React.Fragment>
    )
}
import React from "react";
import { AppAnnouncementListComponent } from "../../../Components/Organisms/Dashbaord/Announcements/List";

export const AppAnnouncementListScreen: React.FC = () => {
  return (
    <React.Fragment>
      <AppAnnouncementListComponent />
    </React.Fragment>
  );
};

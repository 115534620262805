import React from "react";
import { Controller, useForm } from "react-hook-form";
import { couponCreateDto } from "../../../../Interfaces/form";
import { API, APIURLS } from "../../../../Services";
import { AxiosError, AxiosResponse } from "axios";
import toast from "react-hot-toast";
import { useAuthContext } from "../../../../ContextProviders/AuthProvider";

export const AppCouponCreateForm: React.FC = () => {
  const {
    handleSubmit,
    control,
    watch,
   
  } = useForm<couponCreateDto>({
    mode: "onChange",
    defaultValues: {
      code: "",
      description: "",
      _orders_use_coupon: 0,
      amount: 0,
      is_active: true,
      is_coupon_amount_on: false,
      min_amount: 0,
      max_amount: 0,
      is_coupon_schedule: false,
      type: "percentage",
      _coupon_use_dates_from: "",
      _coupon_use_dates_to: "",
    },
  });
  const {accessToken} = useAuthContext()
  const SubmitForm = (data: couponCreateDto) => {
    try {
      API.post(`${APIURLS.coupons.create}`, {
        code:data.code,
        description:data.description,
        _orders_use_coupon:Number(data._orders_use_coupon),
        amount: Number(data.amount),
        is_active:data.is_active,
        is_coupon_amount_on:data.is_coupon_amount_on,
        min_amount: Number(data.min_amount),
        max_amount: Number(data.max_amount),
        is_coupon_schedule: data.is_coupon_schedule,
        type: data.type,
        _coupon_use_dates_from:data._coupon_use_dates_from,
        _coupon_use_dates_to: data._coupon_use_dates_to,
      },{headers:{'Authorization':`Bearer ${accessToken}`}})
        .then((res: AxiosResponse) => {
          if (res.data) {
            toast.success(`New Coupon created.`);
          }
        })
        .catch((err: AxiosError) => {
          toast.error(err.message);
        });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <React.Fragment>
      <form
        onSubmit={handleSubmit(SubmitForm)}
        className="w-full flex flex-col space-y-2 max-w-screen-lg"
      >
        <div className="flex w-full flex-col space-y-1">
          <label className="text-sm font-thin " htmlFor="title">
            Code
          </label>
          <Controller
            name="code"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Don't leave it blank!",
              },
            }}
            render={({
              field: { onBlur, onChange, value, ref },
              formState: { errors },
            }) => (
              <React.Fragment>
                <input
                  ref={ref}
                  type="text"
                  onBlur={onBlur}
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  className="w-full form-input text-sm"
                />
                {errors && errors.code && errors.code.message ? (
                  <React.Fragment>
                    <span className="text-xs text-primary font-light">
                      {errors.code.message}
                    </span>
                  </React.Fragment>
                ) : null}
              </React.Fragment>
            )}
          />
        </div>
        <div className="flex w-full flex-col space-y-1">
          <label className="text-sm font-thin " htmlFor="description">
            Description
          </label>
          <Controller
            name="description"
            control={control}
            rules={{
              required: {
                value: false,
                message: "Don't leave it blank!",
              },
            }}
            render={({
              field: { onBlur, onChange, value, ref },
              formState: { errors },
            }) => (
              <React.Fragment>
                <textarea
                  ref={ref}
                  onBlur={onBlur}
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  className="w-full form-textarea text-sm"
                />
                {errors && errors.description && errors.description.message ? (
                  <React.Fragment>
                    <span className="text-xs text-primary font-light">
                      {errors.description.message}
                    </span>
                  </React.Fragment>
                ) : null}
              </React.Fragment>
            )}
          />
        </div>
        <div className="flex w-full flex-col space-y-1">
          <label className="text-sm font-thin " htmlFor="title">
            How many orders can use??
          </label>
          <Controller
            name="_orders_use_coupon"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Don't leave it blank!",
              },
            }}
            render={({
              field: { onBlur, onChange, value, ref },
              formState: { errors },
            }) => (
              <React.Fragment>
                <input
                  ref={ref}
                  type="number"
                  onBlur={onBlur}
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  className="w-full form-input text-sm"
                />
                {errors &&
                errors._orders_use_coupon &&
                errors._orders_use_coupon.message ? (
                  <React.Fragment>
                    <span className="text-xs text-primary font-light">
                      {errors._orders_use_coupon.message}
                    </span>
                  </React.Fragment>
                ) : null}
              </React.Fragment>
            )}
          />
        </div>
        <div className="flex w-full flex-col space-y-1">
          <label className="text-sm font-thin " htmlFor="title">
            Type
          </label>
          <Controller
            name="type"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Don't leave it blank!",
              },
            }}
            render={({
              field: { onBlur, onChange, value, ref },
              formState: { errors },
            }) => (
              <React.Fragment>
                <select
                  ref={ref}
                  onBlur={onBlur}
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  className="w-full form-select text-sm"
                >
                  <option value="percentage">Percentage</option>
                  <option value="fixed">Fixed</option>
                </select>
                {errors && errors.type && errors.type.message ? (
                  <React.Fragment>
                    <span className="text-xs text-primary font-light">
                      {errors.type.message}
                    </span>
                  </React.Fragment>
                ) : null}
                <div className="w-full flex flex-col space-y-1">
                  <p className="text-xs">
                    <span className="font-bold text-black">*Percentage</span>A
                    percentage coupon is a reduction in the original price of a
                    product or service based on a percentage of that price. For
                    example, a coupon offering a 20% discount means that the
                    final price is reduced by 20% of the original price.
                    Example: If an item costs $100 and there's a 20% discount,
                    the customer pays only $80 (80% of the original price).
                  </p>
                  <p className="text-xs">
                    <span className="font-bold text-black">*Fixed</span>A fixed
                    coupon is a specific, predetermined amount deducted from the
                    original price. Regardless of the original price, the
                    discount amount remains constant. Example: If there's a
                    fixed discount of $10, the customer receives a $10 reduction
                    in the final price, irrespective of the original price.
                  </p>
                </div>
              </React.Fragment>
            )}
          />
        </div>
        <div className="flex w-full flex-col space-y-1">
          <label className="text-sm font-thin " htmlFor="title">
            Amount
          </label>
          <Controller
            name="amount"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Don't leave it blank!",
              },
            }}
            render={({
              field: { onBlur, onChange, value, ref },
              formState: { errors },
            }) => (
              <React.Fragment>
                <input
                  ref={ref}
                  type="number"
                  onBlur={onBlur}
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  className="w-full form-input text-sm"
                />
                {errors && errors.amount && errors.amount.message ? (
                  <React.Fragment>
                    <span className="text-xs text-primary font-light">
                      {errors.amount.message}
                    </span>
                  </React.Fragment>
                ) : null}
              </React.Fragment>
            )}
          />
        </div>
        <div className="flex w-full flex-row space-x-2">
          <Controller
            name="is_coupon_amount_on"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Don't leave it blank!",
              },
            }}
            render={({
              field: { onBlur, onChange, value, ref },
              formState: { errors },
            }) => (
              <React.Fragment>
                <input
                  ref={ref}
                  type="checkbox"
                  onBlur={onBlur}
                  value={String(value)}
                  checked={value}
                  onChange={(e) => onChange(e.target.checked)}
                  className="form-checkbox"
                />
                {errors && errors.amount && errors.amount.message ? (
                  <React.Fragment>
                    <span className="text-xs text-primary font-light">
                      {errors.amount.message}
                    </span>
                  </React.Fragment>
                ) : null}
              </React.Fragment>
            )}
          />
          <label className="text-sm font-thin " htmlFor="title">
            Amount based discount?
          </label>
        </div>
        <div className="w-full flex flex-col space-y-1">
          <p className="text-xs">
            <span className="font-bold text-black">*Min Amount</span>
            The "min amount" or "minimum amount" condition specifies the minimum
            purchase amount that must be met for the coupon to be valid. The
            customer needs to spend at least this amount to qualify for the
            discount offered by the coupon.
          </p>
          <p className="text-xs">
            <span className="font-bold text-black">*Max Amount</span>
            The "max amount" or "maximum amount" condition sets an upper limit
            on the total purchase amount for which the coupon can be applied.
            Once the customer's order reaches this maximum amount, the coupon
            discount will no longer be applicable.
          </p>
        </div>
        {watch("is_coupon_amount_on") ? (
          <React.Fragment>
            <div className="w-full grid grid-cols-12 gap-3">
              <div className="col-span-12 md:col-span-6">
                <div className="flex w-full flex-col space-y-1">
                  <label className="text-sm font-thin " htmlFor="title">
                    Min Amount
                  </label>
                  <Controller
                    name="min_amount"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "Don't leave it blank!",
                      },
                    }}
                    render={({
                      field: { onBlur, onChange, value, ref },
                      formState: { errors },
                    }) => (
                      <React.Fragment>
                        <input
                          ref={ref}
                          type="number"
                          onBlur={onBlur}
                          value={value}
                          onChange={(e) => onChange(e.target.value)}
                          className="w-full form-input text-sm"
                        />
                        {errors &&
                        errors.min_amount &&
                        errors.min_amount.message ? (
                          <React.Fragment>
                            <span className="text-xs text-primary font-light">
                              {errors.min_amount.message}
                            </span>
                          </React.Fragment>
                        ) : null}
                      </React.Fragment>
                    )}
                  />
                </div>
              </div>
              <div className="col-span-12 md:col-span-6">
                <div className="flex w-full flex-col space-y-1">
                  <label className="text-sm font-thin " htmlFor="title">
                    Max Amount
                  </label>
                  <Controller
                    name="max_amount"
                    control={control}
                    rules={{
                      required: {
                        value: watch("is_coupon_amount_on"),
                        message: "Don't leave it blank!",
                      },
                      min: {
                        value: Number(watch("min_amount")),
                        message: "It must be greater than min amount.",
                      },
                    }}
                    render={({
                      field: { onBlur, onChange, value, ref },
                      formState: { errors },
                    }) => (
                      <React.Fragment>
                        <input
                          ref={ref}
                          type="number"
                          onBlur={onBlur}
                          value={value}
                          onChange={(e) => onChange(e.target.value)}
                          className="w-full form-input text-sm"
                        />
                        {errors &&
                        errors.max_amount &&
                        errors.max_amount.message ? (
                          <React.Fragment>
                            <span className="text-xs text-primary font-light">
                              {errors.max_amount.message}
                            </span>
                          </React.Fragment>
                        ) : null}
                      </React.Fragment>
                    )}
                  />
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : null}
        <div className="flex w-full flex-row space-x-2">
          <Controller
            name="is_coupon_schedule"
            control={control}
            render={({
              field: { onBlur, onChange, value, ref },
              formState: { errors },
            }) => (
              <React.Fragment>
                <input
                  ref={ref}
                  type="checkbox"
                  onBlur={onBlur}
                  value={String(value)}
                  checked={value}
                  onChange={(e) => onChange(e.target.checked)}
                  className="form-checkbox"
                />
              </React.Fragment>
            )}
          />
          <label className="text-sm font-thin " htmlFor="title">
            Duration Based ?
          </label>
        </div>
        <div className="w-full flex flex-col space-y-1">
          <p className="text-xs">
            <span className="font-bold text-black">*From Date </span>
            The "from date" is the starting date on which the coupon becomes
            active and can be used by customers. It marks the beginning of the
            period during which the coupon discount is applicable. Example: If a
            coupon has a "from date" of January 1st, it means that customers can
            start using the coupon and availing the associated discount starting
            from January 1st onward.
          </p>
          <p className="text-xs">
            <span className="font-bold text-black">*To Date</span>
            The "to date" is the ending date that marks the conclusion of the
            coupon's validity. After this date, the coupon is no longer active,
            and customers can no longer use it to receive the discount. Example:
            If a coupon has a "to date" of January 31st, it means that the
            coupon is valid until the end of January. After January 31st, the
            coupon discount will no longer be applicable.
          </p>
        </div>
        {watch("is_coupon_schedule") ? (
          <React.Fragment>
            <div className="w-full grid grid-cols-12 gap-3">
              <div className="col-span-12 md:col-span-6">
                <div className="flex w-full flex-col space-y-1">
                  <label className="text-sm font-thin " htmlFor="from_date">
                    From Date
                  </label>
                  <Controller
                    name="_coupon_use_dates_from"
                    control={control}
                    rules={{
                      required: {
                        value: watch("is_coupon_amount_on"),
                        message: "Don't leave it blank!",
                      },
                    }}
                    render={({
                      field: { onBlur, onChange, value, ref },
                      formState: { errors },
                    }) => (
                      <React.Fragment>
                        <input
                          ref={ref}
                          type="date"
                          onBlur={onBlur}
                          value={value}
                          onChange={(e) => onChange(e.target.value)}
                          className="w-full form-input text-sm"
                        />
                        {errors &&
                        errors._coupon_use_dates_from &&
                        errors._coupon_use_dates_from.message ? (
                          <React.Fragment>
                            <span className="text-xs text-primary font-light">
                              {errors._coupon_use_dates_from.message}
                            </span>
                          </React.Fragment>
                        ) : null}
                      </React.Fragment>
                    )}
                  />
                </div>
              </div>
              <div className="col-span-12 md:col-span-6">
                <div className="flex w-full flex-col space-y-1">
                  <label className="text-sm font-thin " htmlFor="to_date">
                    To Date
                  </label>
                  <Controller
                    name="_coupon_use_dates_to"
                    control={control}
                    rules={{
                      required: {
                        value: watch("is_coupon_schedule"),
                        message: "Don't leave it blank!",
                      },
                    }}
                    render={({
                      field: { onBlur, onChange, value, ref },
                      formState: { errors },
                    }) => (
                      <React.Fragment>
                        <input
                          ref={ref}
                          min={watch("_coupon_use_dates_from")}
                          type="date"
                          onBlur={onBlur}
                          value={value}
                          onChange={(e) => onChange(e.target.value)}
                          className="w-full form-input text-sm"
                        />
                        {errors &&
                        errors._coupon_use_dates_to &&
                        errors._coupon_use_dates_to.message ? (
                          <React.Fragment>
                            <span className="text-xs text-primary font-light">
                              {errors._coupon_use_dates_to.message}
                            </span>
                          </React.Fragment>
                        ) : null}
                      </React.Fragment>
                    )}
                  />
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : null}
        <div className="py-2 flex flex-row">
          <button
          
            type="submit"
            className="px-4 font-bold text-sm tracking-widest py-2 bg-primary text-white hover:bg-secondary"
          >
            Save
          </button>
        </div>
      </form>
    </React.Fragment>
  );
};

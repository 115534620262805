import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { API, APIURLS } from "../../../../Services";
import toast from "react-hot-toast";
import { CiSquarePlus, CiSquareQuestion } from "react-icons/ci";
import { AppImagePicker } from "../../../Atoms/AppImagePicker";

import { useParams } from "react-router-dom";
import { paymentProps } from "../../../../Interfaces";
import { useAuthContext } from "../../../../ContextProviders/AuthProvider";

export const AppPaymentEditFormComponent: React.FC = () => {
  const [open, setopen] = useState<boolean>(false);
  const { accessToken } = useAuthContext();
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isValid },
  } = useForm({
    mode: "onChange",

    defaultValues: {
      title: "",
      logo: "",
    },
  });
  const param: any = useParams();
  const getPayment = useCallback(async () => {
    const { data } = await API.get(
      `${APIURLS.payments.get}/${param && param.id}`,
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    if (data) {
      const response: paymentProps = data;
      setValue("logo", response.logo);
      setValue("title", response.title);
    }
  }, [param, setValue, accessToken]);
  useEffect(() => {
    getPayment();
  }, [getPayment]);
  const onSubmit = (data: any) => {
    if (data) {
      API.patch(`${APIURLS.payments.update}/${param && param.id}`, data, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
        .then((res) => {
          if (res.data) {
            toast.success("New Payment updated.", {
              className: "text-sm font-normal",
              icon: <CiSquarePlus className="w-auto h-6" />,
            });
          }
        })
        .catch((err: any) => {
          toast.success(`${JSON.stringify(err.message)}`, {
            className: "text-sm font-normal",
            icon: <CiSquareQuestion className="w-auto h-6" />,
          });
        })
        .finally(() => {
          console.log(`Payment create API done.`);
        });
    }
  };
  return (
    <React.Fragment>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full flex flex-col  max-w-screen-md space-y-2"
      >
        <div className="flex flex-col space-y-2 ">
          <label className="text-sm font-normal" htmlFor="last_name">
            Name
          </label>
          <Controller
            name="title"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Please Don't Leave It Blank.",
              },
            }}
            render={({
              field: { onBlur, onChange, value, ref },
              formState: { errors },
            }) => (
              <React.Fragment>
                <input
                  type="text"
                  ref={ref}
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  onBlur={onBlur}
                  className="w-full bg-white  form-input border-white "
                />
                {errors && errors.title && errors.title.message ? (
                  <React.Fragment>
                    <span className="text-xs text-primary font-light">
                      {errors.title.message}
                    </span>
                  </React.Fragment>
                ) : null}
              </React.Fragment>
            )}
          />
        </div>
        <div className="flex flex-col space-y-2 ">
          <label className="text-sm font-normal" htmlFor="code">
            Logo
          </label>
          <Controller
            name="logo"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Please Don't Leave It Blank.",
              },
            }}
            render={({
              field: { onBlur, value, ref },
              formState: { errors },
            }) => (
              <React.Fragment>
                <img
                  onBlur={onBlur}
                  ref={ref}
                  onClick={() => setopen(!open)}
                  src={value}
                  alt="logo"
                  className="w-auto object-contain object-center h-32 select-none border border-slate-300 rounded-md"
                />
                <AppImagePicker
                  open={open}
                  setOpen={setopen}
                  setValue={setValue}
                  name="logo"
                />
                {errors && errors.logo && errors.logo.message ? (
                  <React.Fragment>
                    <span className="text-xs text-primary font-light">
                      {errors.logo.message}
                    </span>
                  </React.Fragment>
                ) : null}
              </React.Fragment>
            )}
          />
        </div>
        <div className="py-2 flex flex-row">
          <button
            disabled={Boolean(!isValid)}
            type="submit"
            className="px-4 font-bold text-sm tracking-widest py-2 bg-primary text-white hover:bg-secondary"
          >
            Save
          </button>
        </div>
      </form>
    </React.Fragment>
  );
};

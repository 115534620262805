import React from "react";
import { useDashboardContext } from "../../ContextProviders/DashbaordProvider";
import { useAuthContext } from "../../ContextProviders/AuthProvider";
import { FiMenu } from "react-icons/fi";
import { RiUser6Line } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";
import { CiSettings } from "react-icons/ci";
import { LiaSignOutAltSolid } from "react-icons/lia";

export const AppNav: React.FC = () => {
  const { isAside, setIsAside } = useDashboardContext();
  const { user } = useAuthContext();
  const { pathname } = useLocation();
  return (
    <React.Fragment>
      <header className="bg-white border-b-2 border-gray-200 p-4 flex justify-between items-center">
        <div className="flex items-center">
          <button onClick={() => setIsAside(!isAside)} className="lg:hidden">
            <FiMenu className="text-black text-2xl" />
          </button>

          <div className="hidden lg:flex items-center">
            <h1 className="text-2xl font-semibold">
              {pathname.includes("user")
                ? "User Management"
                : pathname.includes("category")
                ? "Category Management"
                : pathname.includes("shipping")
                ? "Shipping Management"
                : pathname.includes("product")
                ? "Product Management"
                : pathname.includes("order")
                ? "Order Management"
                : pathname.includes("setting")
                ? "Setting"
                : pathname.includes("language")
                ? "Languages"
                : pathname.includes("payment")
                ? "Payment"
                : pathname.includes("announcement")
                ? "Announcements"
                : pathname.includes("coupon")
                ? "Coupon"
                : pathname.includes("upload")
                ? "File Management"
                : "Dashbaord"}
                
            </h1>
          </div>
        </div>
        <div className="flex w-full relative max-w-xs justify-end flex-col group">
          <div className="w-full flex justify-end items-center content-center flex-row space-x-4 ">
            <div className="h-10 w-10 bg-gray-300 rounded-full overflow-hidden">
              <RiUser6Line className="w-full h-full text-slate-500" />
            </div>

            <div className=" w-full flex max-w-sm ">
              <div className="flex flex-row justify-between items-center content-center space-x-2">
                <span className="font-bold text-sm text-slate-800">
                  {`${user?.first_name}  ${user?.last_name}`}
                </span>
              </div>
            </div>
          </div>

          <div
            data-aos="fade-down"
            className="mt-10 absolute top-0 hidden group-hover:flex flex-col space-y-2 left-0 w-full max-w-xs bg-white"
          >
            <ul>
              <li className="mb-2">
                <Link
                  to="/dashboard/account_setting"
                  className="flex items-center font-normal text-slate-600 hover:text-state-900 transition duration-300 py-2 px-4 rounded-md"
                >
                  <CiSettings className="mr-2" />
                  <span className="font-normal text-xs tracking-wide">
                    Account Setting
                  </span>
                </Link>
              </li>

              <li className="mb-2">
                <Link
                  to="/logout"
                  className="flex items-center font-normal text-slate-600 hover:text-state-900 transition duration-300 py-2 px-4 rounded-md"
                >
                  <LiaSignOutAltSolid className="mr-2" />
                  <span className="font-normal text-xs tracking-wide">
                    Logout
                  </span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

import React, { useCallback, useEffect, useState } from "react";
import { AppDashbardCard } from "../../../Atoms/AppDashboardCard";
import {
  FaCartPlus,
  FaEuroSign,
  FaShippingFast,
  FaUserFriends,
} from "react-icons/fa";
import { RiProductHuntLine } from "react-icons/ri";
import { MdCategory } from "react-icons/md";
import { CiMoneyCheck1 } from "react-icons/ci";
import { API, APIURLS } from "../../../../Services";
import { AxiosError, AxiosResponse } from "axios";
import { summaryProps } from "../../../../Interfaces";
import { AppOrderList } from "../../../Atoms/AppOrderList";
import { useAuthContext } from "../../../../ContextProviders/AuthProvider";
export const AppDashbarodHomeComponent: React.FC = () => {
  const { accessToken } = useAuthContext();
  const [summaryData, setsummaryData] = useState<summaryProps>();
  const getSummary = useCallback(() => {
    API.post(
      `${APIURLS.summary.admin}`,
      {},
      { headers: { Authorization: `Bearer ${accessToken}` } }
    )
      .then((res: AxiosResponse) => {
        const summary: summaryProps = res.data;
        setsummaryData(summary);
      })
      .catch((err: AxiosError) => {
        console.log(err.message);
      });
  }, [accessToken]);

  useEffect(() => {
    getSummary();
  }, [getSummary]);

  return (
    <React.Fragment>
      {summaryData ? (
        <React.Fragment>
          <div className="w-full max-w-screen-xl mx-auto grid grid-cols-12 gap-5">
            <div className="col-span-12 md:col-span-3">
              <AppDashbardCard
                icon={<FaUserFriends className="w-auto h-12 mr-2 text-white" />}
                title="Users"
                total={summaryData.users ? summaryData.users : 0}
              />
            </div>
            <div className="col-span-12 md:col-span-3">
              <AppDashbardCard
                icon={<FaEuroSign className="w-auto h-12 mr-2 text-white" />}
                title="Total Sale"
                total={summaryData.total_sale ? summaryData.total_sale : 0}
              />
            </div>
            <div className="col-span-12 md:col-span-3">
              <AppDashbardCard
                icon={<FaEuroSign className="w-auto h-12 mr-2 text-white" />}
                title="Today Sale"
                total={summaryData.today_sale ? summaryData.today_sale : 0}
              />
            </div>
            <div className="col-span-12 md:col-span-3">
              <AppDashbardCard
                icon={<FaCartPlus className="w-auto h-12 mr-2 text-white" />}
                title="Order"
                total={summaryData.orders ? summaryData.orders : 0}
              />
            </div>
            <div className="col-span-12 md:col-span-3">
              <AppDashbardCard
                icon={
                  <RiProductHuntLine className="w-auto h-12 mr-2 text-white" />
                }
                title="Products"
                total={summaryData.products ? summaryData.products : 0}
              />
            </div>
            <div className="col-span-12 md:col-span-3">
              <AppDashbardCard
                icon={<MdCategory className="w-auto h-12 mr-2 text-white" />}
                title="Categories"
                total={summaryData.categories ? summaryData.categories : 0}
              />
            </div>
            <div className="col-span-12 md:col-span-3">
              <AppDashbardCard
                icon={<CiMoneyCheck1 className="w-auto h-12 mr-2 text-white" />}
                title="Payments"
                total={summaryData.payments ? summaryData.payments : 0}
              />
            </div>
            <div className="col-span-12 md:col-span-3">
              <AppDashbardCard
                icon={
                  <FaShippingFast className="w-auto h-12 mr-2 text-white" />
                }
                title="Shippings"
                total={summaryData.shippings ? summaryData.shippings : 0}
              />
            </div>
            <div className="col-span-12 md:col-span-4">
              <AppOrderList
                orders={summaryData.pending_orders}
                header="Pending Orders"
              />
            </div>
            <div className="col-span-12 md:col-span-4">
              <AppOrderList
                orders={summaryData.today_orders}
                header="Today Orders"
              />
            </div>
            <div className="col-span-12 md:col-span-4">
              <AppOrderList
                orders={summaryData.latest_orders}
                header="Latest Orders"
              />
            </div>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="w-full flex flex-col justify-center items-center content-center h-screen">
            loading..
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

import React from "react";
import { Controller, useForm } from "react-hook-form";
import { announceCreateDto } from "../../../../Interfaces/form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the styles for the editor

import { DeltaStatic, Sources } from "quill";
import { API, APIURLS } from "../../../../Services";
import toast from "react-hot-toast";
import { AxiosResponse } from "axios";
import { useAuthContext } from "../../../../ContextProviders/AuthProvider";

export const AppAnnouncementCreateForm: React.FC = () => {
  const {
    handleSubmit,
    control,
    watch,
   
  } = useForm<announceCreateDto>({
    mode: "onChange",
    defaultValues: {
      title: "",
      html_body: "",
      type: "is_daily",
      from_date: "",
      to_date: "",
      specific_date: "",
      is_active: true,
    },
  });
  const SubmitForm = (data: announceCreateDto) => {
    const { accessToken } = useAuthContext();
    try {
      API.post(`${APIURLS.announcements.create}`, data, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
        .then((res: AxiosResponse) => {
          if (res.data) {
            toast.success(`New Announcement created.`);
          }
        })
        .catch((err: any) => {
          toast.error(err.message);
        });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <React.Fragment>
      <form
        onSubmit={handleSubmit(SubmitForm)}
        className="w-full flex flex-col space-y-2 max-w-screen-lg"
      >
        <div className="flex w-full flex-col space-y-1">
          <label className="text-sm font-thin " htmlFor="title">
            Title
          </label>
          <Controller
            name="title"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Don't leave it blank!",
              },
            }}
            render={({
              field: { onBlur, onChange, value, ref },
              formState: { errors },
            }) => (
              <React.Fragment>
                <input
                  ref={ref}
                  type="text"
                  onBlur={onBlur}
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  className="w-full form-input text-sm"
                />
                {errors && errors.title && errors.title.message ? (
                  <React.Fragment>
                    <span className="text-xs text-primary font-light">
                      {errors.title.message}
                    </span>
                  </React.Fragment>
                ) : null}
              </React.Fragment>
            )}
          />
        </div>
        <div className="flex w-full flex-col space-y-1">
          <label className="text-sm font-thin " htmlFor="html_body">
            Body
          </label>
          <Controller
            name="html_body"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Don't leave it blank!",
              },
            }}
            render={({
              field: { onBlur, onChange, value, ref },
              formState: { errors },
            }) => (
              <React.Fragment>
                <div className="w-full">
                  <ReactQuill
                    ref={ref}
                    onBlur={onBlur}
                    theme="snow"
                    value={value}
                    className="bg-white"
                    onChange={(
                      value: string,
                      data: DeltaStatic,
                      source: Sources,
                      editor: ReactQuill.UnprivilegedEditor
                    ) => onChange(value)}
                    modules={{
                      toolbar: [
                        [{ header: [1, 2, 3, false] }],
                        ["bold", "italic", "underline", "strike"],
                        ["link", "image"],
                        [{ list: "ordered" }, { list: "bullet" }],
                        ["clean"],
                        ["code"],
                      ],
                    }}
                  />
                </div>
                {errors && errors.html_body && errors.html_body.message ? (
                  <React.Fragment>
                    <span className="text-xs text-primary font-light">
                      {errors.html_body.message}
                    </span>
                  </React.Fragment>
                ) : null}
              </React.Fragment>
            )}
          />
        </div>
        <div className="flex w-full flex-col space-y-1">
          <label className="text-sm font-thin " htmlFor="title">
            Type
          </label>
          <Controller
            name="type"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Don't leave it blank!",
              },
            }}
            render={({
              field: { onBlur, onChange, value, ref },
              formState: { errors },
            }) => (
              <React.Fragment>
                <select
                  className="w-full form-select"
                  ref={ref}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                >
                  <option value="">Please select One</option>
                  <option value="is_daily">Daily</option>
                  <option value="is_range">Range</option>
                  <option value="is_specific">Specific Date</option>
                </select>
                {errors && errors.type && errors.type.message ? (
                  <React.Fragment>
                    <span className="text-xs text-primary font-light">
                      {errors.type.message}
                    </span>
                  </React.Fragment>
                ) : null}
                <div className="w-full flex flex-col">
                  <p>
                    <span className="text-black font-bold text-xs">
                      *Daily Announcement:
                    </span>
                    <span className="text-xs font-thin">
                      This announcement repeats every day.
                    </span>
                  </p>
                  <p>
                    <span className="text-black font-bold text-xs">
                      *Range Announcement:
                    </span>
                    <span className="text-xs font-thin">
                      This announcement is valid for a specified range of
                      dates.It can override "Daily" Announcement.
                    </span>
                  </p>
                  <p>
                    <span className="text-black font-bold text-xs">
                      *Specific Date Announcement:
                    </span>
                    <span className="text-xs font-thin">
                      This announcement is valid for a specific date only.It can
                      override other Announcement.
                    </span>
                  </p>
                </div>
              </React.Fragment>
            )}
          />
        </div>
        {watch("type") === "is_range" ? (
          <React.Fragment>
            <div className="w-full grid grid-cols-12 gap-3">
              <div className="col-span-12 md:col-span-6">
                <div className="flex w-full flex-col space-y-1">
                  <label className="text-sm font-thin " htmlFor="from_date">
                    From Date
                  </label>
                  <Controller
                    name="from_date"
                    control={control}
                    rules={{
                      required: {
                        value: watch("type") === "is_range",
                        message: "Don't leave it blank!",
                      },
                    }}
                    render={({
                      field: { onBlur, onChange, value, ref },
                      formState: { errors },
                    }) => (
                      <React.Fragment>
                        <input
                          ref={ref}
                          type="date"
                          onBlur={onBlur}
                          value={value}
                          onChange={(e) => onChange(e.target.value)}
                          className="w-full form-input text-sm"
                        />
                        {errors &&
                        errors.from_date &&
                        errors.from_date.message ? (
                          <React.Fragment>
                            <span className="text-xs text-primary font-light">
                              {errors.from_date.message}
                            </span>
                          </React.Fragment>
                        ) : null}
                      </React.Fragment>
                    )}
                  />
                </div>
              </div>
              <div className="col-span-12 md:col-span-6">
                <div className="flex w-full flex-col space-y-1">
                  <label className="text-sm font-thin " htmlFor="to_date">
                    To Date
                  </label>
                  <Controller
                    name="to_date"
                    control={control}
                    rules={{
                      required: {
                        value: watch("type") === "is_range",
                        message: "Don't leave it blank!",
                      },
                    }}
                    render={({
                      field: { onBlur, onChange, value, ref },
                      formState: { errors },
                    }) => (
                      <React.Fragment>
                        <input
                          ref={ref}
                          min={watch("from_date")}
                          type="date"
                          onBlur={onBlur}
                          value={value}
                          onChange={(e) => onChange(e.target.value)}
                          className="w-full form-input text-sm"
                        />
                        {errors && errors.to_date && errors.to_date.message ? (
                          <React.Fragment>
                            <span className="text-xs text-primary font-light">
                              {errors.to_date.message}
                            </span>
                          </React.Fragment>
                        ) : null}
                      </React.Fragment>
                    )}
                  />
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : null}
        {watch("type") === "is_specific" ? (
          <React.Fragment>
            <div className="flex w-full flex-col space-y-1">
              <label className="text-sm font-thin " htmlFor="to_date">
                Specific Date
              </label>
              <Controller
                name="specific_date"
                control={control}
                rules={{
                  required: {
                    value: watch("type") === "is_specific",
                    message: "Don't leave it blank!",
                  },
                }}
                render={({
                  field: { onBlur, onChange, value, ref },
                  formState: { errors },
                }) => (
                  <React.Fragment>
                    <input
                      ref={ref}
                      type="date"
                      onBlur={onBlur}
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      className="w-full form-input text-sm"
                    />
                    {errors &&
                    errors.specific_date &&
                    errors.specific_date.message ? (
                      <React.Fragment>
                        <span className="text-xs text-primary font-light">
                          {errors.specific_date.message}
                        </span>
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                )}
              />
            </div>
          </React.Fragment>
        ) : null}
        <div className="py-2 flex flex-row">
          <button
           
            type="submit"
            className="px-4 font-bold text-sm tracking-widest py-2 bg-primary text-white hover:bg-secondary"
          >
            Save
          </button>
        </div>
      </form>
    </React.Fragment>
  );
};

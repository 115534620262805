import React from "react";
import { orderProps } from "../../Interfaces";
import { fCurrency } from "../../Helpers";
import { CiClock1 } from "react-icons/ci";
import moment from "moment";
interface props {
  order: orderProps;
}
export const AppOrderCard: React.FC<props> = ({ order }) => {
  return (
    <React.Fragment>
      {order ? (
        <div  className="w-ful group bg-white  order_border">
          <div className="w-full px-2 py-2 flex flex-col space-y-2 ">
            <div className="flex flex-row w-full justify-between items-center content-center">
              <h6 className="text-lg font-bold tracking-widest  rounded-full px-3 py-1">
                {order?.order_no}
              </h6>
              <h6 className="text-xs uppercase font-bold tracking-widest text-white bg-primary rounded-full px-3 py-1">
                {order?.status}
              </h6>
            </div>
            <div  className="w-full hidden group-hover:block">
            <div className="flex flex-row w-full justify-end items-center content-center">
              <div className="flex flex-row justify-end items-center content-center space-x-2">
                <CiClock1 className="text-white w-auto h-4" />
                <span className="text-xs font-thin text-white">
                  {moment(new Date(order.created_at)).format(
                    "YYYY-MM-DD HH:mm A"
                  )}
                </span>
              </div>
            </div>
            {order.items.map((item, index) => (
              <div key={index} className="w-full grid grid-cols-12 gap-4">
                <div className="col-span-6">
                  <span className="text-xs font-normal">{item.name}</span>
                  <span className="text-xs font-thin">
                    {` x ${item.quantity}`}
                  </span>
                </div>
                <div className="col-span-6">
                  <div className="w-full flex flex-row justify-end items-center content-center">
                    <span className="text-sm font-bold text-yellow-600 text-right">
                      {fCurrency(Number(item.price))}
                    </span>
                  </div>
                </div>
              </div>
            ))}
            <div className="w-full py-2 border-t border-black">
              <div className="w-full grid grid-cols-12 gap-4">
                <div className="col-span-6">
                  <span className="text-xs font-thin uppercase">Sub Total</span>
                </div>
                <div className="col-span-6">
                  <div className="w-full flex flex-row justify-end items-center content-center">
                    <span className="text-sm font-bold text-yellow-600 text-right">
                      {fCurrency(Number(Number(order.sub_total).toFixed(2)))}
                    </span>
                  </div>
                </div>
              </div>
              <div className="w-full grid grid-cols-12 gap-4">
                <div className="col-span-6">
                  <span className="text-xs font-thin uppercase">Payment</span>
                </div>
                <div className="col-span-6">
                  <div className="w-full flex flex-row justify-end items-center content-center">
                    <span className="text-sm font-bold text-yellow-600 text-right">
                      {order.payment?.title}
                    </span>
                  </div>
                </div>
              </div>
              {order._order_type === "delivery" ? (
                <div className="w-full grid grid-cols-12 gap-4">
                  <div className="col-span-6">
                    <span className="text-xs font-thin uppercase">
                      Shipping
                    </span>
                  </div>
                  <div className="col-span-6">
                    <div className="w-full flex flex-row justify-end items-center content-center">
                      <span className="text-sm font-bold text-yellow-600 text-right">
                        {fCurrency(
                          Number(Number(order.shipping_cost).toFixed(2))
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              ) : null}
              {order.coupon_amount && Number(order.coupon_amount) > 0 ? (
                <div className="w-full grid grid-cols-12 gap-4">
                  <div className="col-span-6">
                    <span className="text-xs font-thin uppercase">
                      Coupon Discount
                    </span>
                  </div>
                  <div className="col-span-6">
                    <div className="w-full flex flex-row justify-end items-center content-center">
                      <span className="text-sm font-bold text-yellow-600 text-right">
                        -{" "}
                        {fCurrency(
                          Number(Number(order.coupon_amount).toFixed(2))
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              ) : null}
              {order._order_type === "collection" &&
              Number(order.collection_discount) > 0 ? (
                <div className="w-full grid grid-cols-12 gap-4">
                  <div className="col-span-6">
                    <span className="text-xs font-thin uppercase">
                      Collection Discount
                    </span>
                  </div>
                  <div className="col-span-6">
                    <div className="w-full flex flex-row justify-end items-center content-center">
                      <span className="text-sm font-bold text-yellow-600 text-right">
                        -{" "}
                        {fCurrency(
                          Number(Number(order.collection_discount).toFixed(2))
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="w-full py-2 border-t border-black">
              <div className="w-full grid grid-cols-12 gap-4">
                <div className="col-span-6">
                  <span className="text-xs font-thin uppercase">Total</span>
                </div>
                <div className="col-span-6">
                  <div className="w-full flex flex-row justify-end items-center content-center">
                    <span className="text-sm font-bold text-yellow-600 text-right">
                      {fCurrency(Number(Number(order.total).toFixed(2)))}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
};

import React from "react";
import { Controller, useForm } from "react-hook-form";
import { API, APIURLS } from "../../../../Services";
import toast from "react-hot-toast";
import { RiAddCircleLine } from "react-icons/ri";
import { useAuthContext } from "../../../../ContextProviders/AuthProvider";

export const AppUserCreateFormComponent: React.FC = () => {
  const {
    control,
    handleSubmit,
    getValues,
   
  } = useForm({
    mode: "onChange",

    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      password: "",
      confirm_password: "",
      role: "user",
    },
  });
  const {accessToken} = useAuthContext()
  const onSubmit = (data: any) => {
    if (data) {
      API.post(`${APIURLS.users.create}`, data,{headers:{'Authorization':`Bearer ${accessToken}`}})
        .then((res) => {
          if (res.data) {
            toast.success("New user created.", {
              className: "text-sm font-normal",
              icon: <RiAddCircleLine className="w-auto h-6" />,
            });
          }
        })
        .catch((err: any) => {
          console.log(err);
        })
        .finally(() => {
          console.log(`User create API done.`);
        });
    }
  };
  return (
    <React.Fragment>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full flex flex-col max-w-screen-lg mx-auto space-y-2"
      >
        <div className="w-full grid grid-cols-12 gap-3">
          <div className="col-span-12 md:col-span-6">
            <div className="flex flex-col space-y-2 ">
              <label className="text-sm font-normal" htmlFor="first_name">
                First Name
              </label>
              <Controller
                name="first_name"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Please Don't Leave It Blank.",
                  },
                }}
                render={({
                  field: { onBlur, onChange, value, ref },
                  formState: { errors },
                }) => (
                  <React.Fragment>
                    <input
                      type="text"
                      ref={ref}
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      onBlur={onBlur}
                      className="w-full bg-white  form-input border-white "
                    />
                    {errors &&
                    errors.first_name &&
                    errors.first_name.message ? (
                      <React.Fragment>
                        <span className="text-xs text-primary font-light">
                          {errors.first_name.message}
                        </span>
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                )}
              />
            </div>
          </div>
          <div className="col-span-12 md:col-span-6">
            <div className="flex flex-col space-y-2 ">
              <label className="text-sm font-normal" htmlFor="last_name">
                Last Name
              </label>
              <Controller
                name="last_name"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Please Don't Leave It Blank.",
                  },
                }}
                render={({
                  field: { onBlur, onChange, value, ref },
                  formState: { errors },
                }) => (
                  <React.Fragment>
                    <input
                      type="text"
                      ref={ref}
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      onBlur={onBlur}
                      className="w-full bg-white  form-input border-white "
                    />
                    {errors && errors.last_name && errors.last_name.message ? (
                      <React.Fragment>
                        <span className="text-xs text-primary font-light">
                          {errors.last_name.message}
                        </span>
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                )}
              />
            </div>
          </div>
        </div>

        <div className="w-full grid grid-cols-12 gap-3">
          <div className="col-span-12 md:col-span-6">
            {" "}
            <div className="flex flex-col space-y-2">
              <label className="text-sm font-normal" htmlFor="email">
                Email
              </label>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: "Please enter your email.",
                  pattern: {
                    value: /^\S+@\S+$/i,
                    message: "Invalid email format.",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <React.Fragment>
                    <input
                      type="text"
                      {...field}
                      className="w-full bg-white  form-input border-white"
                    />
                    {error ? (
                      <span className="text-xs text-primary font-light">
                        {error.message}
                      </span>
                    ) : null}
                  </React.Fragment>
                )}
              />
            </div>
          </div>
          <div className="col-span-12 md:col-span-6">
            <div className="flex flex-col space-y-2">
              <label className="text-sm font-normal" htmlFor="phone">
                Phone
              </label>
              <Controller
                name="phone"
                control={control}
                rules={{
                  required: "Please enter your phone number.",
                  pattern: {
                    value: /^\d+$/,
                    message: "Please enter a valid phone number.",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <React.Fragment>
                    <input
                      type="text"
                      {...field}
                      className="w-full bg-white  form-input border-white"
                    />
                    {error ? (
                      <span className="text-xs text-primary font-light">
                        {error.message}
                      </span>
                    ) : null}
                  </React.Fragment>
                )}
              />
            </div>
          </div>
        </div>
        <div className="w-full grid grid-cols-12 gap-3">
          <div className="col-span-12 md:col-span-6">
            <div className="flex flex-col space-y-2">
              <label className="text-sm font-normal" htmlFor="password">
                Password
              </label>
              <Controller
                name="password"
                control={control}
                rules={{
                  required: "Please enter a password.",
                  minLength: {
                    value: 6,
                    message: "Password must be at least 6 characters long.",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <React.Fragment>
                    <input
                      type="password"
                      {...field}
                      className="w-full bg-white  form-input border-white"
                    />
                    {error ? (
                      <span className="text-xs text-primary font-light">
                        {error.message}
                      </span>
                    ) : null}
                  </React.Fragment>
                )}
              />
            </div>
          </div>
          <div className="col-span-12 md:col-span-6">
            <div className="flex flex-col space-y-2">
              <label className="text-sm font-normal" htmlFor="confirm_password">
                Confirm Password
              </label>
              <Controller
                name="confirm_password"
                control={control}
                rules={{
                  required: "Please confirm your password.",
                  validate: (value) =>
                    value === getValues("password") ||
                    "Passwords do not match.",
                }}
                render={({ field, fieldState: { error } }) => (
                  <React.Fragment>
                    <input
                      type="password"
                      {...field}
                      className="w-full bg-white  form-input border-white"
                    />
                    {error ? (
                      <span className="text-xs text-primary font-light">
                        {error.message}
                      </span>
                    ) : null}
                  </React.Fragment>
                )}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-2">
          <label className="text-sm font-normal" htmlFor="role">
            Role
          </label>
          <Controller
            name="role"
            control={control}
            defaultValue="user"
            rules={{
              required: "Please select a role.",
            }}
            render={({ field, fieldState: { error } }) => (
              <React.Fragment>
                <div className="flex items-center space-x-2">
                  <input
                    type="radio"
                    id="user"
                    {...field}
                    value="user"
                    checked={field.value === "user"}
                    className="form-radio text-primary"
                  />
                  <label htmlFor="user">User</label>
                </div>
                <div className="flex items-center space-x-2">
                  <input
                    type="radio"
                    id="admin"
                    {...field}
                    value="admin"
                    checked={field.value === "admin"}
                    className="form-radio text-primary"
                  />
                  <label htmlFor="admin">Admin</label>
                </div>

                {error ? (
                  <span className="text-xs text-primary font-light">
                    {error.message}
                  </span>
                ) : null}
              </React.Fragment>
            )}
          />
        </div>
        <div className="py-2 flex flex-row">
          <button
          
            type="submit"
            className="px-4 font-bold text-sm tracking-widest py-2 bg-primary text-white hover:bg-secondary"
          >
            Save
          </button>
        </div>
      </form>
    </React.Fragment>
  );
};

import React from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { RiArrowRightSLine, RiQuestionAnswerLine } from "react-icons/ri";
import { AxiosResponse } from "axios";
import { Link, useParams } from "react-router-dom";
import { API, APIURLS } from "../Services";
interface props {
  password: string;
  confirm_password: string;
  reset_token: string;
}
export const AppPasswordUpdateScreen: React.FC = () => {
  const { reset_token } = useParams();
  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid },
  } = useForm<props>({
    mode: "onChange",
    defaultValues: {
      reset_token: reset_token,
      confirm_password: "",
      password: "",
    },
  });

  const SubmitForm = (data: props) => {
    try {
      API.post(`${APIURLS.auth.update_password}`, data)
        .then((res: AxiosResponse) => {
          if (
            res.data &&
            res.data.status &&
            res.data.status === 200 &&
            res.data.message
          ) {
           
            setError("reset_token", {
              type: "validate",
              message:res.data.message,
            });
          } else if (
            res.data &&
            res.data.status &&
            res.data.status === 404 &&
            res.data.message
          ) {
            setError("confirm_password", {
              type: "validate",
              message: res.data.message,
            });
          }
        })
        .catch((error: any) => {
          toast.error(`Something wrong`, {
            icon: <RiQuestionAnswerLine className="w-auto h-6" />,
          });
        });
    } catch (error) {
      toast.error(`Something wrong`, {
        icon: <RiQuestionAnswerLine className="w-auto h-6" />,
      });
    }
  };

  return (
    <React.Fragment>
      <div className="w-full flex  flex-col text-white h-screen justify-center items-center content-center space-y-2">
        <form
          onSubmit={handleSubmit(SubmitForm)}
          className="w-full mx-auto max-w-screen-sm py-6 bg-black rounded-md shadow-md px-4"
        >
          <div className="w-full flex flex-col space-y-2">
            <h6 className="text-lg font-bold">Reset password</h6>
            <p className="text-sm tracking-wider font-thin">
              Please provide a new password to continue using your JAPANISCHES
              SUSHI RESTAURANT IN FRANKFURT AM MAIN's account.
            </p>
          </div>
          <div className="w-full flex flex-col space-y-2 py-4 px-2">
            <label className="text-sm font-thin" htmlFor="email">
              New Password
            </label>
            <Controller
              control={control}
              name="password"
              rules={{
                required: {
                  value: true,
                  message: "Please Don't Leave It Blank.",
                },
                minLength: {
                  value: 8,
                  message:
                    "Password must be longer than or equal to 8 characters.",
                },
              }}
              render={({
                field: { onBlur, onChange, value, ref },
                formState: { errors },
              }) => (
                <React.Fragment>
                  <input
                    type="password"
                    ref={ref}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    onBlur={onBlur}
                    className="w-full bg-black  form-input border-white "
                  />
                  {errors && errors.password && errors.password.message ? (
                    <React.Fragment>
                      <span className="text-xs tracking-widest text-white font-light">
                        {errors.password.message}
                      </span>
                    </React.Fragment>
                  ) : null}
                </React.Fragment>
              )}
            />
          </div>
          <div className="w-full flex flex-col space-y-2 py-4 px-2">
            <label className="text-sm font-thin" htmlFor="email">
              Confirm Password
            </label>
            <Controller
              control={control}
              name="confirm_password"
              rules={{
                required: {
                  value: true,
                  message: "Please Don't Leave It Blank.",
                },
              }}
              render={({
                field: { onBlur, onChange, value, ref },
                formState: { errors },
              }) => (
                <React.Fragment>
                  <input
                    type="password"
                    ref={ref}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    onBlur={onBlur}
                    className="w-full bg-black  form-input border-white "
                  />
                  {errors &&
                  errors.confirm_password &&
                  errors.confirm_password.message ? (
                    <React.Fragment>
                      <span className="text-xs tracking-widest text-white font-light">
                        {errors.confirm_password.message}
                      </span>
                    </React.Fragment>
                  ) : null}
                  {errors &&
                  errors.reset_token &&
                  errors.reset_token.message ? (
                    <React.Fragment>
                      <p className="text-sm tracking-wider font-thin">
                         {errors.reset_token.message} . 
                        <Link
                          className="text-primary underline"
                          to={`/login`}
                        >
                         login here
                        </Link>
                      </p>
                    </React.Fragment>
                  ) : null}
                </React.Fragment>
              )}
            />
          </div>
          <div className="flex w-full px-2 py-2 flex-col">
            <button
              disabled={!isValid}
              type="submit"
              className="w-full px-2 py-2 flex flex-row justify-center items-center content-center bg-primary hover:bg-secondary text-white"
            >
              <span className="text-sm font-bold tracking-widest flex-grow">
                Reset password
              </span>
              <RiArrowRightSLine className="w-auto h-6 " />
            </button>
          </div>
          <div className="w-full flex flex-col space-y-2">
            <p className="text-sm tracking-wider font-thin">
              If you need additional help, please contact{" "}
              <Link className="text-primary underline" to={`/kontakt`}>
                customer service
              </Link>
            </p>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

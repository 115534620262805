import AppBg from "../Assets/bg.png";
import AppLogo from "../Assets/logo.png";
import BoxHeaderImg from "../Assets/ann_header-1.jpg";
import BoxFooterImg from "../Assets/ann_footer.jpg";
import NotFoundImg from "../Assets/notfound.svg";
export const AppConfig = {
  imagePath: "https://3mopslcyqk.execute-api.eu-central-1.amazonaws.com/production",
  apiPath: "https://3mopslcyqk.execute-api.eu-central-1.amazonaws.com/production",
  appBg: AppBg,
  appLogo: AppLogo,
  boxHeaderImg: BoxHeaderImg,
  boxFooterImg: BoxFooterImg,
  notFoundImg: NotFoundImg,
};
